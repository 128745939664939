import React, {useState, useContext, useEffect} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import logo from '../../../assets/images/logonero.png'
import google from '../../../assets/images/google_G.svg'
import blue_dotes from '../../../assets/images/blue_dotes.png'
import smallStar from '../../../assets/images/smallStar.png'
import bigstar from '../../../assets/images/bigstar.png'
import sign_in from '../../../assets/images/snapmemories/login/sign_in_screen.png'
import { AuthContext } from '../../../configs/AuthContext'
import {resendConfermationCode} from "../../../configs/auth";
import toastMessage from "../../../utilities/alertMessage";
import {Toaster} from "react-hot-toast";
function Main() {

    const { signIn } = useContext(AuthContext)
    const [error, setError] = useState("")
    const location = useLocation();
    const navigate = useNavigate();
    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Indirizzo email non valido')
            .required('L\'email è obbligatoria'),
        password: Yup.string()
            .min(8, 'La password deve contenere almeno 8 caratteri')
            .matches(/[0-9]/, 'La password deve contenere almeno un numero')
            .matches(/[A-Z]/, 'La password deve contenere almeno una lettera maiuscola')
            .required('La password è obbligatoria')
    })
    useEffect(() => {
        const verification = new URLSearchParams(location.search).get('verification');
        const reset = new URLSearchParams(location.search).get('reset');
        if(verification==="true"){
            toastMessage({"esito": "OK", "message": `Email verificata con successo`})

        }
        else if(reset==="true"){
            toastMessage({"esito": "OK", "message": `Passaword modificata con successo`})

        }
    }, []);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema,
    })

    // Funzione per accedere con user e password
    const handleSignIn = async (type) => {
        setError("")
        try {
            if((type==='cognito' && formik.isValid && formik.values.email.length>0 && formik.values.password.length>0) || type==='google')
                await signIn(formik.values.email, formik.values.password, type)
        } catch (err) {
            if(err.message.includes('User is not confirmed')){
                try {
                    await resendConfermationCode(formik.values.email);
                    navigate('/confirm-sign-up?email=' + formik.values.email);
                } catch (err) {
                    setError(err.message);
                }
            }
            else {
                setError(err.message)
            }
        }
    }

    return (
        <div className="page_wrapper">
            <div className="full_bg">
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <section className="signup_section">
                    <div className="container">
                        <div className="top_part">
                            <Link style={{fontSize:"25px"}} to="/" className="back_btn">
                                <i className="icofont-arrow-left" /> Torna alla home
                            </Link>
                            <Link className="navbar-brand" to="#">
                                <img src={logo} style={{width:"260px"}} alt="immagine" />
                            </Link>
                        </div>

                        <div className="form_block">
                            <div className="form_side">
                                <div className="section_title">
                                    <span className="title_badge">Bentornato</span>
                                    <h2>
                                        <span>Accedi</span> al tuo account
                                    </h2>
                                    <p>Accedi rapidamente ai tuoi acquisti</p>
                                </div>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="error">{formik.errors.email}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="error">{formik.errors.password}</div>
                                        ) : null}
                                    </div>
                                    {error && <p className="error-2">{error}</p>}
                                    <div className="forgate_check">
                                        <Link to="/forgot-password">Password dimenticata</Link>
                                    </div>
                                    <div className="btn_block">
                                        <button className="btn puprple_btn ml-0"
                                                onClick={() => handleSignIn('cognito')}>Accedi ora
                                        </button>
                                        <div className="btn_bottom"/>
                                    </div>
                                    <button className="btn google_btn" onClick={() => handleSignIn('google')}>
                                        <img src={google} alt="immagine"/> Accedi con Google
                                    </button>
                                </form>

                                <div className="sign_in_here">
                                    <p>
                                        Non hai un account? <Link to="/sign-up">Registrati qui</Link>
                                    </p>
                                </div>

                            </div>

                            <div className="side_screen">
                                <div className="dotes_blue">
                                    <img src={blue_dotes} alt="immagine"/>
                                </div>
                                <div className="left_icon">
                                    <img src={smallStar} alt="immagine" />
                                </div>
                                <div className="right_icon">
                                    <img src={bigstar} alt="immagine" />
                                </div>
                                <div className="scrren">
                                    <img src={sign_in} alt="immagine" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Main
