import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import '../src/assets/css/aos.css'
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/icofont.min.css'
import '../src/assets/css/magnific-popup.min.css'
import '../src/assets/css/responsive.css'
import '../src/assets/css/style.css'
import ScrollToTop from "./ScrollToTop";
import '../src/assets/css/profile-page.css'
import '../src/assets/css/loading.css'
import '../src/assets/css/home.css'
import { AuthProvider } from "./configs/AuthContext";


export default function App() {
  return (
    <>
      <AuthProvider>
        <Router >
            <ScrollToTop/>
            <Routing />
        </Router>
      </AuthProvider>

    </>
  );
}