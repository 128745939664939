import * as React from "react"
const ApplePaySvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_2"
        width={58}
        height={58}
        data-name="Layer 2"
        {...props}
    >
        <defs>
            <style>{".cls-2{fill-rule:evenodd}"}</style>
        </defs>
        <path d="M47.852 14H10.558c-.129 0-.259 0-.388.006a5.571 5.571 0 0 0-.844.074 2.884 2.884 0 0 0-.8.264 2.7 2.7 0 0 0-1.179 1.18 2.862 2.862 0 0 0-.264.8 5.68 5.68 0 0 0-.075.844V39.555a5.679 5.679 0 0 0 .075.843 2.879 2.879 0 0 0 .264.8 2.694 2.694 0 0 0 1.177 1.182 2.853 2.853 0 0 0 .8.265 5.841 5.841 0 0 0 .844.074H48.7a5.841 5.841 0 0 0 .844-.074 2.853 2.853 0 0 0 .8-.265 2.694 2.694 0 0 0 1.181-1.18 2.816 2.816 0 0 0 .264-.8 5.678 5.678 0 0 0 .074-.843c0-.129.005-.258.006-.387V17.558c0-.129 0-.258-.006-.387a5.679 5.679 0 0 0-.074-.844 2.8 2.8 0 0 0-.264-.8 2.7 2.7 0 0 0-1.179-1.18 2.884 2.884 0 0 0-.8-.264 5.571 5.571 0 0 0-.844-.074c-.13 0-.259 0-.388-.006Z" />
        <path
            d="M47.852 14.958h.453c.123 0 .245 0 .369.006a4.8 4.8 0 0 1 .7.059 1.921 1.921 0 0 1 .538.176 1.72 1.72 0 0 1 .439.319 1.743 1.743 0 0 1 .321.442 1.882 1.882 0 0 1 .175.537 4.768 4.768 0 0 1 .059.7c0 .122.005.244.006.369V39.163q0 .183-.006.366a4.687 4.687 0 0 1-.06.7 1.868 1.868 0 0 1-.174.535 1.73 1.73 0 0 1-.762.761 1.862 1.862 0 0 1-.534.175 4.77 4.77 0 0 1-.7.06c-.124 0-.247 0-.374.005H10.563c-.124 0-.247 0-.367-.005a4.728 4.728 0 0 1-.7-.06 1.859 1.859 0 0 1-.538-.176 1.7 1.7 0 0 1-.44-.32 1.718 1.718 0 0 1-.32-.44 1.889 1.889 0 0 1-.175-.538 4.655 4.655 0 0 1-.059-.7c0-.122-.005-.245-.006-.367v-21.6c0-.122 0-.245.006-.367a4.568 4.568 0 0 1 .06-.7 1.858 1.858 0 0 1 .175-.538 1.722 1.722 0 0 1 .76-.759 1.9 1.9 0 0 1 .537-.176 4.8 4.8 0 0 1 .7-.059c.122 0 .245-.005.367-.006h37.289"
            style={{
                fill: "#fff",
            }}
        />
        <path d="M19.25 23.662a2.51 2.51 0 0 0 .576-1.786 2.481 2.481 0 0 0-1.646.852 2.346 2.346 0 0 0-.591 1.716 2.073 2.073 0 0 0 1.661-.782ZM19.818 24.568c-.916-.055-1.7.52-2.134.52s-1.108-.493-1.833-.48a2.7 2.7 0 0 0-2.3 1.4c-.985 1.7-.26 4.216.7 5.6.465.684 1.026 1.438 1.765 1.411.7-.028.971-.452 1.82-.452s1.094.452 1.833.438c.766-.014 1.245-.684 1.71-1.369a6.029 6.029 0 0 0 .766-1.575 2.487 2.487 0 0 1-1.492-2.261 2.534 2.534 0 0 1 1.2-2.122 2.62 2.62 0 0 0-2.035-1.11Z" />
        <path
            d="M31.182 26.033A3.222 3.222 0 0 0 27.8 22.66h-3.849v10.27h1.594v-3.509h2.207a3.258 3.258 0 0 0 3.43-3.388Zm-3.808 2.05h-1.829v-4.078h1.837a1.915 1.915 0 0 1 2.17 2.035 1.923 1.923 0 0 1-2.178 2.043ZM34.382 28.588c-1.78.1-2.783.9-2.783 2.214a2.27 2.27 0 0 0 2.491 2.256 2.674 2.674 0 0 0 2.362-1.36h.036v1.232h1.473v-5.16c0-1.5-1.16-2.463-2.975-2.463-1.85 0-3 1-3.088 2.349h1.451a1.435 1.435 0 0 1 1.581-1.1c.939 0 1.5.5 1.5 1.331v.576Zm2.049.947v.59a1.8 1.8 0 0 1-1.914 1.716c-.826 0-1.367-.427-1.367-1.082s.52-1.046 1.438-1.11Z"
            className="cls-2"
        />
        <path d="M39.352 35.684v-1.246a2.791 2.791 0 0 0 .5.029 1.247 1.247 0 0 0 1.329-1.067c0-.014.135-.455.135-.462l-2.7-7.495h1.665l1.893 6.093h.026l1.893-6.093h1.623l-2.806 7.878c-.64 1.815-1.38 2.4-2.932 2.4a6.028 6.028 0 0 1-.626-.037Z" />
    </svg>
)
export default ApplePaySvg
