import {env} from "../../../configs/EnvironmentConfig";
import {isMobile} from "react-device-detect";

function DetailsOrdine({checkout}) {
    return (
        <div className="your-order-area">
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", margin: "0 0 20px"}}>

                <div style={{
                    border: "2px solid #629665",
                    padding: "5px",
                    backgroundColor: "#629665",
                    borderRadius: "25px",
                    color: "white",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%"
                }}
                >
                    <div style={{marginRight: "6px"}}><i class="fa fa-gift"
                                                         style={{fontSize: "23px"}}></i></div>

                    <div>Riepilogo del tuo evento</div>
                </div>

            </div>
            <div className="your-order-wrap gray-bg-4">
                <div className="your-order-product-info">
                    <div className="your-order-top">
                        <ul>
                            <li>Pacchetto</li>
                            <li>{checkout?.piano === 'unico' ? 'premium' : checkout?.piano}</li>
                        </ul>
                    </div>
                    <div className="your-order-middle">
                        <ul>

                            <li key={"tema"}>
                                        <span className="order-middle-left">
                                           Tema
                                        </span>{" "}
                                <span className="order-price">
                                         {checkout?.theme === "dark" ? 'Blu scuro' : checkout?.theme}
                                        </span>
                            </li>
                            <li key={"start"}>
                                        <span className="order-middle-left">
                                           Inizio evento
                                        </span>{" "}
                                <span className="order-price">
                                         {checkout?.start}
                                        </span>
                            </li>
                            <li key={"end"}>
                                        <span className="order-middle-left">
                                           Fine evento
                                        </span>{" "}
                                <span className="order-price">
                                         {checkout?.end}
                                        </span>
                            </li>
                        </ul>
                    </div>

                    <div className="your-order-bottom">
                        <ul>
                            <li className="your-order-shipping">Forma QRCode</li>
                            <li>{checkout?.shape}</li>
                        </ul>
                        {checkout?.locandina !== null &&
                            <>
                                <ul>
                                    <li className="your-order-shipping">Locandina</li>
                                    <li>{checkout?.locandina?.temaLocandina}</li>
                                </ul>
                                <ul>
                                    <li className="your-order-shipping">Messaggio personalizzato</li>
                                    {!isMobile &&
                                        < li> {checkout?.locandina?.messaggioPersonalizzato}</li>
                                    }
                                </ul>
                                {isMobile &&
                                    <ul>
                                        {checkout?.locandina?.messaggioPersonalizzato}
                                    </ul>
                                }
                                <ul>
                                    <li className="your-order-shipping">Numero di locandine</li>

                                    <li>
                                        {parseInt(checkout?.locandina?.numeroLocandine) + parseInt(checkout?.locandina?.numeroLocandineAggiuntive)}
                                    </li>

                                </ul>

                            </>
                        }
                    </div>


                    <div className="your-order-total">
                        <div className="your-order-bottom">
                            {checkout?.locandina !== null && checkout?.locandina?.numeroLocandineAggiuntive !== 0 &&
                                <ul>
                                    <li className="your-order-shipping">Prezzo locandine aggiuntive</li>

                                    <li> {"€" +
                                        ((
                                            (parseInt(checkout?.locandina?.numeroLocandineAggiuntive) / 10 * 1000)
                                        ) / 100).toFixed(2)

                                    }</li>


                                </ul>
                            }
                        </div>
                        <ul>
                            <li className="order-total">{"TOTALE"}</li>
                            <li> €
                                {checkout?.locandina !== null ?
                                    ((
                                        (env.PIANO[checkout?.piano]) +
                                        (parseInt(checkout?.locandina?.numeroLocandineAggiuntive) / 10 * 1000)
                                    ) / 100).toFixed(2)
                                    :
                                    ((
                                        (env.PIANO[checkout?.piano])) / 100).toFixed(2)
                                }


                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DetailsOrdine;