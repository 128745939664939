import React, {useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from '../../../assets/images/logonero.png'
import google_G from '../../../assets/images/google_G.svg'
import blue from '../../../assets/images/blue_dotes.png'
import smallStar from '../../../assets/images/smallStar.png'
import bigstar from '../../../assets/images/bigstar.png'
import sign_in from '../../../assets/images/snapmemories/login/sign_in_screen.png'
import {signUp} from '../../../configs/auth'
import {AuthContext} from "../../../configs/AuthContext";


function Main() {
    const { signIn } = useContext(AuthContext)
    const navigate = useNavigate();
    const [error, setError] = useState("")

    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: '',
            terms: false,
        },
        validationSchema: Yup.object({
            username: Yup.string().required('Il nome è obbligatorio'),
            email: Yup.string().email('Indirizzo email non valido').required('L\'email è obbligatoria'),
            password: Yup.string()
                .min(8, 'La password deve contenere almeno 8 caratteri')
                .matches(/[A-Z]/, 'La password deve contenere almeno una lettera maiuscola')
                .matches(/\d/, 'La password deve contenere almeno un numero')
                .required('La password è obbligatoria'),
            terms: Yup.bool().oneOf([true], 'Devi accettare i termini e le condizioni'),
        }),
        onSubmit: async (values) => {
            setError("");
            try {
                await signUp(values.username, values.email, values.password);
                navigate('/confirm-sign-up?email=' + values.email);
            } catch (err) {
                setError(err.message);
            }
        },
    });

    return (
        <>
            <div className="page_wrapper">
                <div className="full_bg">
                    <section className="signup_section">
                        <div className="container">
                            <div className="top_part">
                                <Link style={{fontSize:"25px"}} to="/" className="back_btn">
                                    <i className="icofont-arrow-left" /> Torna alla home
                                </Link>
                                <Link className="navbar-brand" to="#">
                                    <img src={logo} alt="image" />
                                </Link>
                            </div>
                            <div className="form_block">
                                <div className="form_side">
                                    <div className="section_title">
                                        <span className="title_badge">Inizia</span>
                                        <h2>
                                            <span>Crea</span> un account
                                        </h2>

                                    </div>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Nome"
                                                {...formik.getFieldProps('username')}
                                            />
                                            {formik.touched.username && formik.errors.username ? (
                                                <div className="error">{formik.errors.username}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email"
                                                {...formik.getFieldProps('email')}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="error">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                {...formik.getFieldProps('password')}
                                            />
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className="error">{formik.errors.password}</div>
                                            ) : null}
                                        </div>
                                        {error && <p className="error-2">{error}</p>}
                                        <div className="forgate_check" style={{alignItems: "flex-start",flexDirection: "column"}}>
                                            <div className="coustome_checkbox">
                                                <label htmlFor="remamber_check">
                                                    <input
                                                        type="checkbox"
                                                        id="remamber_check"
                                                        {...formik.getFieldProps('terms')}
                                                    />
                                                    <span className="checkmark" />
                                                    Accetto tutti i Termini, Privacy e Tariffe
                                                </label>
                                            </div>
                                            {formik.touched.terms && formik.errors.terms ? (
                                                <div className="error">{formik.errors.terms}</div>
                                            ) : null}
                                        </div>
                                        <div className="btn_block">
                                            <button type="submit" className="btn puprple_btn ml-0">Registrati ora</button>
                                            <div className="btn_bottom" />
                                        </div>
                                    </form>
                                    <button className="btn google_btn" onClick={() => signIn('', '', 'google')}>
                                        <img src={google_G} alt="image" /> Registrati con Google
                                    </button>
                                    <div className="sign_in_here">
                                        <p>
                                            Hai già un account? <Link to="/sign-in">Accedi qui</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="side_screen">
                                    <div className="dotes_blue">
                                        <img src={blue} alt="image" />
                                    </div>
                                    <div className="left_icon">
                                        <img src={smallStar} alt="image" />
                                    </div>
                                    <div className="right_icon">
                                        <img src={bigstar} alt="image" />
                                    </div>
                                    <div className="scrren">
                                        <img src={sign_in} alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Main
