import React, {useEffect, useState} from 'react';
import defaultPhoto from '../../assets/images/checkout.png';
import defaultVideo from '../../assets/images/checkout.png';
import {env} from "../../configs/EnvironmentConfig";
import EventUploadManager from "./EventUploadManager";


function UpdateProfile({ event,reloadEvent }) {

    const [image,setImage] = useState(null)
    const [video,setVideo] = useState(null)

    useEffect(() => {
        /*const fileFromUrl = async (key) => {
            const response = await fetch(`${env.API_ENDPOINT_SERVER_MAFFLE}/maffle/snapmemories/downloadObject?key=${key}`, {
                method: 'GET',
                headers: {
                    'Accept': 'image/jpeg',
                },
            });
            const data = await response.blob();
            const filename = key.split('/')[key.split('/').length-1]
            return new File([data], filename, { type: data.type });
        };*/
        const fileFromUrl = async (url,key) => {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'image/jpeg',
                },
            });
            const data = await response.blob();
            const filename = key.split('/')[key.split('/').length-1]
            return new File([data], filename, { type: data.type });
        };
        if (event?.custom?.profilePhoto && event.custom.profilePhoto !== "false") {
            try {
                let key = event?.custom?.profilePhoto.replace(/^https?:\/\/[^\/]+\//, "");
                const cndUrl = event?.custom?.profilePhoto.replace(/^https:\/\/[^\/]+/, env.URL_CDN.replace(/\/+$/, ""))
                fileFromUrl(cndUrl,key).then((file) => {
                    setImage([{
                        source: URL.createObjectURL(file),
                        options: {
                            type: 'local',
                            file: file
                        }
                    }]);
                });
            }
            catch (e) {
                setImage([]);
            }
        } else {
            setImage([]);
        }
        if (event?.custom?.profileVideo && event.custom.profileVideo !== "false") {
            try {
                const cndUrl = event?.custom?.profilePhoto.replace(/^https:\/\/[^\/]+/, env.URL_CDN.replace(/\/+$/, ""))
                let key = event?.custom?.profileVideo.replace(/^https?:\/\/[^\/]+\//, "");
                fileFromUrl(cndUrl,key).then((file) => {
                    setVideo([{
                        source: URL.createObjectURL(file),
                        options: {
                            type: 'local',
                            file: file
                        }
                    }]);
                });
            }catch (e) {
                setVideo([]);
            }
        } else {
            setVideo([]);
        }

    }, [event]);
    return (
        <>
            <section className="row_am foto_profilo_section">
                <div className="container">
                    <div className="section_title">
                        <h2>
                            Aggiorna il tuo <span>profilo</span>
                        </h2>
                    </div>
                    <div className="row" style={{paddingRight: "19px",paddingLeft: "15px"}}>
                        {/* Foto Profilo */}

                        <div className="experts_box" style={{width: '100%', maxHeight: '100%'}}>
                            <div className="row" >
                                <div className="col-md-6">
                                    <div className="text" >
                                        <h3 style={{fontSize:"32px"}}>Foto Profilo</h3>
                                    </div>
                                    <EventUploadManager reloadEvent={reloadEvent} media={'image'} file={image} event={event}/>
                                    {event?.custom?.profilePhoto !== undefined && event?.custom?.profilePhoto !== null && event?.custom?.profilePhoto !== "" && event?.custom?.profilePhoto !== "false" &&
                                        <img
                                            src={
                                                event?.custom?.profilePhoto !== undefined && event?.custom?.profilePhoto !== "false"
                                                    ? event?.custom?.profilePhoto
                                                    : defaultPhoto
                                            }
                                            className="profile-photo"
                                            alt="Foto Profilo"
                                            style={{
                                                width: '100%',
                                                height: '550px',
                                                objectFit: 'cover',
                                                marginBottom: "10px"
                                            }}
                                        />}

                                </div>
                                <div className="col-md-6">
                                    <div className="text">
                                        <h3 style={{fontSize:"32px"}}>Video Profilo</h3>
                                    </div>
                                    <EventUploadManager reloadEvent={reloadEvent} media={'video'} file={video} event={event}/>
                                    {event?.custom?.profileVideo !== undefined && event?.custom?.profileVideo !== null && event?.custom?.profileVideo !== "" && event?.custom?.profileVideo !== "false" &&
                                        <video
                                            src={
                                                event?.custom?.profileVideo !== undefined && event?.custom?.profileVideo !== "false"
                                                    ? event?.custom?.profileVideo
                                                    : defaultVideo
                                            }
                                            className="profile-video"
                                            controls
                                            alt="Video Profilo"
                                            style={{
                                                width: '100%',
                                                height: '550px',
                                                objectFit: 'cover',
                                                borderRadius: "20px",
                                                marginBottom: "10px"
                                            }}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                        <div>


                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default UpdateProfile;
