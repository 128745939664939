import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { confirmPassword } from "../../../configs/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function ResetPassword() {
    const [error, setError] = useState("");
    const [fromPage, setFromPage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const username = new URLSearchParams(location.search).get("username");
        const fromPage = new URLSearchParams(location.search).get("page");
        if (!username || !fromPage) {
            navigate("/sign-in");
        }
        setFromPage(fromPage)
        formik.setFieldValue("username", username);
    }, [location, navigate]);

    const formik = useFormik({
        initialValues: {
            username: "",
            confirmationCode: "",
            newPassword: "",
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Il nome utente è obbligatorio"),
            confirmationCode: Yup.string()
                .matches(/^\d{6}$/, "Il codice deve essere un numero di 6 cifre")
                .required("Il codice di conferma è obbligatorio"),
            newPassword: Yup.string()
                .min(8, "La password deve avere almeno 8 caratteri")
                .matches(/\d/, "La password deve contenere almeno un numero")
                .matches(/[A-Z]/, "La password deve contenere almeno una lettera maiuscola")
                .required("La nuova password è obbligatoria"),
        }),
        onSubmit: async (values) => {
            setError("");
            try {
                await confirmPassword(values.username, values.confirmationCode, values.newPassword);
                navigate("/sign-in?reset=true");
            } catch (err) {
                setError(err.message);
            }
        },
    });

    return (
        <div className="page_wrapper">
            <div className="full_bg">
                <section className="signup_section">
                    <div className="container">
                        <div className="top_part">
                            {fromPage==='home'?
                                <Link style={{fontSize:"25px"}} to="/login" className="back_btn">
                                    <i className="icofont-arrow-left" /> Torna al login
                                </Link>
                                :
                                <Link style={{fontSize:"25px"}} to="/profile-page" className="back_btn">
                                    <i className="icofont-arrow-left" /> Torna agli acquisti
                                </Link>
                            }
                        </div>
                        <div className="form_block">
                            <div className="form_side" style={{width:"100%",padding:"15px 250px"}} >
                                <div className="section_title"></div>
                                <span className="title_badge">Password dimenticata</span>
                                <h2>
                                    <span>Cambia la tua password</span>
                                </h2>
                                <p></p>

                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            placeholder="Nome utente"
                                            value={formik.values.username}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                            name="username"
                                            disabled
                                        />
                                        {formik.touched.username && formik.errors.username ? (
                                            <div className="error">{formik.errors.username}</div>
                                        ) : null}

                                        <input
                                            type="text"
                                            placeholder="Codice di conferma"
                                            value={formik.values.confirmationCode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                            name="confirmationCode"
                                        />
                                        {formik.touched.confirmationCode && formik.errors.confirmationCode ? (
                                            <div className="error">{formik.errors.confirmationCode}</div>
                                        ) : null}

                                        <input
                                            type="password"
                                            placeholder="Nuova password"
                                            value={formik.values.newPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                            name="newPassword"
                                        />
                                        {formik.touched.newPassword && formik.errors.newPassword ? (
                                            <div className="error">{formik.errors.newPassword}</div>
                                        ) : null}
                                    </div>
                                    {error && <p className="error-2">{error}</p>}
                                    <div className="btn_block" type="submit">
                                        <button className="btn puprple_btn ml-0" type="submit">
                                            Invia
                                        </button>
                                        <div className="btn_bottom" />
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    );
}
