import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Bluedots from '../../assets/images/blue_dotes.png'
import {isMobile} from "react-device-detect";
import {env} from "../../configs/EnvironmentConfig";

function SelectPlan({handleTabChange}) {

    const [tabMenu, tabActive] = useState({a: true});

    return (
        <>
            <section className="row_am pricing_section white_text">
                <div className="pricing_inner">
                    <div className="container">
                        <div className="dotes_blue">
                            <img src={Bluedots} alt="image"/>
                        </div>
                        <div className="section_title">
                            <span className="title_badge">Step numero 1</span>
                            <h2>Seleziona il nostro pacchetto</h2>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="monthly" role="tabpanel"
                                 aria-labelledby="monthly-tab">
                                <div className="pricing_pannel">
                                    <div className="row">
                                        <div className="col-md-2">
                                        </div>
                                        <div className="col-md-8">
                                            <div className="pannel_block">
                                                <div className="heading" style={{
                                                    textAlign: "center"
                                                }}>
                                                    <h3>Premium</h3>
                                                </div>
                                                <div className="pricing"
                                                     style={{
                                                         display: "flex",
                                                         justifyContent: "center",
                                                         flexDirection: isMobile ? "column" : "row",
                                                         alignItems: "baseline"

                                                     }}>

                                                    <h3>
                                                        €{env.PIANO['unico'].toFixed(2) / 100}
                                                    </h3>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                        <ul className="features">

                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Foto e video illimitati</p>
                                                            </li>
                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>1 anno di storage</p>
                                                            </li>
                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Temi personalizzabili</p>
                                                            </li>
                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Nessuna installazione</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <ul className="features">

                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Emoticons animate</p>
                                                            </li>
                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Modalità griglia</p>
                                                            </li>
                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Classifica per la miglior foto</p>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-lg-4 col-3">
                                                    </div>
                                                    <div className="col-lg-4 col-6"  style={{
                                                        textAlign: "center"
                                                    }}>

                                                        <div className="btn_block">
                                                            <button
                                                                onClick={() => handleTabChange({b: true}, {piano: "unico"}, "piano")}
                                                                className="btn puprple_btn ml-0">Prosegui
                                                            </button>
                                                            <div className="btn_bottom"/>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-3">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <p className="contact_text text-center">
                                Non sei sicuro di quale scegliere? <br/>
                                <Link
                                    to="https://wa.me/+393791785539?text=Sarei%20interessato%20all'%20acquisto%20di%20un%20QRcode">Contattaci
                                </Link> per
                                maggiori informazioni!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SelectPlan;