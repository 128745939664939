const locale = {
    /*'https://5p8slj28cj.execute-api.eu-central-1.amazonaws.com/latest'*/
    API_ENDPOINT_SERVER_MAFFLE: 'https://5p8slj28cj.execute-api.eu-central-1.amazonaws.com/latest',
    S3_BUCKET: 'snapmemories-sviluppo',
    URL_CDN: 'https://d3aqkgcgylfqcp.cloudfront.net/',
    AWS:{
      accessKey:"AKIATAO37WVFFUUXSXLH",
      secretKey:"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",
      region:"eu-south-1"
    },
    STRIPE_CONFIG: {
        basic: 'price_1PWwrgJ5Q4L2IzvQ4THpxTmF',
        medium: 'price_1PXoHgJ5Q4L2IzvQcj7lrBHq',
        premium: 'price_1PXoI8J5Q4L2IzvQirPKKbA2',
        unico: 'price_1QSiDOJ5Q4L2IzvQ3pi5IUOv',
        locandina: 'price_1PXoAvJ5Q4L2IzvQonM7VIQ3'
    },
    LOGIN: {
        app_host: 'http://localhost:3000',
        profile_page: '/profile-page',
        cognitoConfig: {
            UserPoolId: 'eu-south-1_5rYHdjjVs',
            ClientId: '7t7uct7vucfshgf9nhvf51voa2',
            CognitoDomain: "https://snapmemories-sviluppo.auth.eu-south-1.amazoncognito.com",
            GoogleAppClientSecret: "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",
        }
    },
    PIANO: {
        basic: 8999,
        medium: 12999,
        premium: 15999,
        unico: 9999
    }
}


const sviluppo = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://5p8slj28cj.execute-api.eu-central-1.amazonaws.com/latest',
    S3_BUCKET: 'snapmemories-sviluppo',
    URL_CDN: 'https://d3aqkgcgylfqcp.cloudfront.net/',
    AWS:{
        accessKey:"AKIATAO37WVFFUUXSXLH",
        secretKey:"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",
        region:"eu-south-1"
    },
    STRIPE_CONFIG: {
        basic: 'price_1PWwrgJ5Q4L2IzvQ4THpxTmF',
        medium: 'price_1PXoHgJ5Q4L2IzvQcj7lrBHq',
        premium: 'price_1PXoI8J5Q4L2IzvQirPKKbA2',
        unico: 'price_1QSiDOJ5Q4L2IzvQ3pi5IUOv',
        locandina: 'price_1PXoAvJ5Q4L2IzvQonM7VIQ3'
    },
    LOGIN: {
        app_host: 'https://snapshop-gamma.vercel.app',
        profile_page: '/profile-page',
        cognitoConfig: {
            UserPoolId: 'eu-south-1_5rYHdjjVs',
            ClientId: '7t7uct7vucfshgf9nhvf51voa2',
            CognitoDomain: "https://snapmemories-sviluppo.auth.eu-south-1.amazoncognito.com",
            GoogleAppClientSecret: "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",
        }
    },
    PIANO: {
        basic: 8999,
        medium: 12999,
        premium: 15999,
        unico: 9999
    }
};


const produzione = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://yrq0qnl8rh.execute-api.eu-central-1.amazonaws.com/latest',
    S3_BUCKET: 'snapmemories-produzione',
    URL_CDN: 'https://d1lyhupni6omoa.cloudfront.net/',
    STRIPE_CONFIG: {
        basic: 'price_1PflgzJ5Q4L2IzvQ9Ss4Pe4b',
        medium: 'price_1PfliCJ5Q4L2IzvQsTcreJZ0',
        premium: 'price_1PfliUJ5Q4L2IzvQ3XO2evoL',
        unico: 'price_1QSiBMJ5Q4L2IzvQjsLZ7lAH',
        locandina: 'price_1Pfls1J5Q4L2IzvQYLBmtymW'
    },
    AWS:{
        accessKey:"AKIATAO37WVFA5WIDBGA",
        secretKey:"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",
        region:"eu-south-1"
    },
    LOGIN: {
        app_host: 'https://www.snapmemories.it',
        profile_page: '/profile-page',
        cognitoConfig: {
            UserPoolId: 'eu-south-1_9qGhnKHBJ',
            ClientId: '1je87ggttpi5s2v6ikr42vg9sk',
            CognitoDomain:"https://snapmemories.auth.eu-south-1.amazoncognito.com",
            GoogleAppClientSecret:"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"
        }
    },
    PIANO: {
        basic: 8999,
        medium: 12999,
        premium: 15999,
        unico: 9999
    }
};

const getEnv = () => {
    switch (process.env.REACT_APP_ENV?.trim()) {
        case 'sviluppo':
            return sviluppo;
        case 'produzione':
            return produzione;
        default:
            return locale
    }
}

export const env = getEnv();
