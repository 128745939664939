import { createContext, useState } from "react"
import * as auth from "./auth"
import {env} from "./EnvironmentConfig";

const AuthContext = createContext()

function AuthProvider({ children }) {

  const [user, setUser] = useState(null)
  const getCurrentUser = async () => {
    const loginType = localStorage.getItem('login_type')

      if (loginType !== null) {
        try {
          let user = null;
          if (loginType === "google") {
            user = await auth.getGoogleCurrentUser()
          } else if (loginType === "cognito"){
            user = await auth.getCognitoCurrentUser()
          }
          console.log("[GET USER] user",user)
          return user
        } catch (err) {
          console.error("Error get Current User : ", err)
          return null
        }
      } else  {
        return null
      }


  }
  const signIn = async (username, password,type) => {
    if(type==='google') {
      try {
        const url = env.LOGIN.cognitoConfig.CognitoDomain+'/oauth2/authorize';
        const params = new URLSearchParams({
          response_type: 'code',
          client_id: env.LOGIN.cognitoConfig.ClientId,
          redirect_uri: env.LOGIN.app_host+'/auth/callback',
          state: 'state',
          identity_provider: 'Google',
          scope: 'profile email openid'
        }).toString();
        window.location.href = `${url}?${params}`
      }catch (e) {
        throw new Error('Autenticazione fallita')
      }
    }else if(type==='cognito'){

      await auth.signIn(username, password)
      localStorage.setItem('login_type', 'cognito');
      window.location.href = env.LOGIN.app_host+env.LOGIN.profile_page;

     await getCurrentUser()
    }
  }

  const signOut = async () => {
    const loginType = localStorage.getItem('login_type')
    localStorage.removeItem("login_type")
    setUser(null)
    if (loginType==="google") {
      const token = localStorage.getItem("refreshToken")
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("accessToken")
      localStorage.removeItem("idToken")
      //(appunti francesco) TODO non riesco a far funzionare il googleSignOut quindi per il momento ho semplicemente rimosso i token
      //await  auth.googleSignOut(token);
    }
    else if(loginType==="cognito") {
      await auth.signOut()
    }

    window.location.href = "/sign-in";
  }



  const authValue = {
    user,
    signIn,
    signOut,
    setUser,
    getCurrentUser
  }

  return (
      <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }