import React from 'react'


function Loading({minHeight}) {

        return (
            <>
                <div className="loading-section" style={{minHeight: minHeight!==undefined ? minHeight : '100%' }}>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </>
        )

}

export default Loading