import * as React from "react"
const GooglePaySvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_2"
        width={58}
        height={58}
        data-name="Layer 2"
        {...props}
    >
        <defs>
            <style>{".cls-2{fill:#3c4043;fill-rule:evenodd}"}</style>
        </defs>
        <path
            d="M39.241 18H18.708a11.667 11.667 0 1 0 0 23.333h20.533a11.667 11.667 0 1 0 0-23.333Z"
            style={{
                fill: "#fff",
            }}
        />
        <path
            d="M18.708 18h20.533a11.667 11.667 0 1 1 0 23.333H18.708a11.667 11.667 0 1 1 0-23.333ZM43.4 19.791a10.581 10.581 0 0 0-4.159-.846H18.708a10.581 10.581 0 0 0-4.159.846 10.815 10.815 0 0 0-5.717 5.716 10.648 10.648 0 0 0 0 8.319 10.815 10.815 0 0 0 5.717 5.716 10.563 10.563 0 0 0 4.159.846h20.533a10.563 10.563 0 0 0 4.159-.846 10.815 10.815 0 0 0 5.717-5.716 10.648 10.648 0 0 0 0-8.319 10.815 10.815 0 0 0-5.717-5.716Z"
            className="cls-2"
        />
        <path
            d="M27.959 34.024v-3.535h1.85a2.673 2.673 0 0 0 1.919-.741 2.512 2.512 0 0 0 0-3.686 2.677 2.677 0 0 0-1.919-.753h-2.97v8.715Zm0-4.6v-3.036h1.873a1.51 1.51 0 0 1 1.108 2.584 1.492 1.492 0 0 1-1.108.45ZM37.421 28.529a2.772 2.772 0 0 0-1.96-.665 2.734 2.734 0 0 0-2.409 1.179l.986.618a1.7 1.7 0 0 1 1.481-.793 1.58 1.58 0 0 1 1.085.408 1.269 1.269 0 0 1 .461.992v.256a3.263 3.263 0 0 0-1.639-.367 2.941 2.941 0 0 0-1.867.548 1.764 1.764 0 0 0-.7 1.47 1.862 1.862 0 0 0 .665 1.458 2.4 2.4 0 0 0 1.651.584 2.13 2.13 0 0 0 1.849-1.017h.047v.828h1.068v-3.679a2.362 2.362 0 0 0-.718-1.82Zm-3.033 4.393a.885.885 0 0 1-.374-.729 1 1 0 0 1 .461-.823 1.916 1.916 0 0 1 1.138-.327 2.3 2.3 0 0 1 1.458.415 1.648 1.648 0 0 1-.554 1.23 1.778 1.778 0 0 1-1.26.525 1.372 1.372 0 0 1-.869-.291Z"
            className="cls-2"
        />
        <path
            d="m44.229 28.057-3.734 8.586H39.34l1.389-3-2.462-5.582h1.219l1.774 4.281h.023l1.727-4.281Z"
            style={{
                fill: "#3c4043",
            }}
        />
        <path
            d="M23.5 29.784a5.863 5.863 0 0 0-.093-1.051h-4.691v1.925h2.7a2.319 2.319 0 0 1-1 1.55v1.249h1.609a4.891 4.891 0 0 0 1.475-3.673Z"
            style={{
                fill: "#4285f4",
            }}
        />
        <path
            d="M20.417 32.209a3.027 3.027 0 0 1-4.505-1.584h-1.661v1.288a5 5 0 0 0 4.466 2.752 4.77 4.77 0 0 0 3.31-1.208Z"
            style={{
                fill: "#34a853",
            }}
        />
        <path
            d="M15.755 29.669a3.012 3.012 0 0 1 .157-.956v-1.288h-1.66a5 5 0 0 0 0 4.489l1.66-1.289a3.008 3.008 0 0 1-.157-.956Z"
            style={{
                fill: "#fabb05",
            }}
        />
        <path
            d="M18.717 26.651a2.72 2.72 0 0 1 1.918.749l1.426-1.425a4.8 4.8 0 0 0-3.344-1.3 5 5 0 0 0-4.466 2.752l1.661 1.289a2.988 2.988 0 0 1 2.805-2.065Z"
            style={{
                fill: "#e94235",
            }}
        />
    </svg>
)
export default GooglePaySvg
