import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { Navigate } from "react-router-dom";
import Header from "../components/Header/Main";
import Loading from "../shared-components/Loading";
import Footer from "../components/Footer/Main";

function RouteGuard({ children }) {
  const { getCurrentUser,setUser,user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  function findIdToken() {
    const regex = /idToken$/; // Regex che cerca chiavi che terminano con 'idToken'

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && regex.test(key)) {  // Verifica se la chiave corrisponde alla regex
        return localStorage.getItem(key); // Se trovata, restituisce il valore
      }
    }
    return null; // Se non trovata, restituisce null
  }
  useEffect(() => {
    const checkToken = async () => {
      try {
        const userGetCurrent = await getCurrentUser();
        if(userGetCurrent){
          setUser(
          {
          ...userGetCurrent,
              "token": findIdToken()
          }
          )
        }
        else{
          setUser(null)
        }
      } catch (error) {
        console.error("Errore route guard: ", error);
        setUser(null)
      }
      finally {
        setLoading(false)
      }
    };

    checkToken().then(r => console.log("[ROUTE GUARD] token controllato"));
  }, []);

  if (loading) {
    return  (
        <div>
          <Header />
          <Loading minHeight={"100vh"}/>
          <Footer/>

        </div>
    )
  }
  if (!loading && user===null) {
    return <Navigate to="/sign-in" />;
  }
  if(!loading && user){
    return children
  }

}

export default RouteGuard;
