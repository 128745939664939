import { useFormik } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "../../../configs/auth";
import {Link, useNavigate} from "react-router-dom";

export default function ForgotPassword() {
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Indirizzo email non valido')
                .required('L\'email è obbligatoria'),
        }),
        onSubmit: async (values, { setSubmitting, setStatus }) => {
            try {
                setStatus({error: ''})
                await forgotPassword(values.email);
                setStatus({ success: true });
                navigate('/reset-password?page=home&username=' + values.email);
            } catch (error) {
                setStatus({ error: error.message });
            }
            setSubmitting(false);
        },
    });


    return (
        <div className="page_wrapper">
            <div className="full_bg">
                <section className="signup_section">
                    <div className="container">
                        <div className="top_part">
                            <Link style={{fontSize:"25px"}} to="/login" className="back_btn">
                                <i className="icofont-arrow-left" /> Torna al login
                            </Link>
                        </div>
                        <div className="form_block">
                            <div className="form_side" style={{width:"100%",padding:"15px 300px",textAlign: "center"}}>
                                <span className="title_badge">Password dimenticata</span>
                                <h2>
                                    <span>Hai dimenticato </span> la password?
                                </h2>
                                <p>Inserisci la tua email per ricevere il codice di verifica</p>

                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="error">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                {formik.status?.error && <p className="error-2">{formik.status.error}</p>}
                                <div className="btn_block">
                                    <button type="submit" className="btn puprple_btn ml-0" disabled={formik.isSubmitting}>
                                        Invia
                                    </button>
                                    <div className="btn_bottom" />
                                </div>
                            </form>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
