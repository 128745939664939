import React, {useEffect, useState} from 'react';
import AereoSvg from "./components/AereoSvg";
import PalloncinoSvg from "./components/PalloncinoSvg";
import BicchiereSvg from "./components/BicchiereSvg";
import TortaSvg from "./components/TortaSvg";
import CameraSvg from "./components/CameraSvg";
import StandardSvg from "./components/StandardSvg";
import {isMobile} from "react-device-detect";

function ConfigureEvent({checkout, handleTabChange}) {

    const [shape, setShape] = useState('standard')
    const [locandina, setLocandina] = useState("0")
    const [messaggioPersonalizzato, setMessaggioPersonalizzato] = useState('')

    const [numeroLocandine, setNumeroLocandine] = useState(0)
    const [errors, setErrors] = useState({});

    const onFinish = () => {

        let validationErrors = {};

        if (checkout.piano === 'unico') {
            handleTabChange({e: true}, {
                shape: shape,
                locandina: null
            }, "qrcode")
        } else {

            if (locandina === "0" && checkout.piano !== 'basic') validationErrors.locandina = "Seleziona una locandina per l'evento.";
            if (locandina !== "0" && checkout.piano === 'basic' && parseInt(numeroLocandine) === 0) validationErrors.numeroLocandine = "Scegli il numero di locandine";
            if (locandina !== "0" && messaggioPersonalizzato === '') validationErrors.messaggioPersonalizzato = "Scrivi un messaggio da inserire sulla locandina";

            if (Object.keys(validationErrors).length > 0) {
                console.log(errors)
                setErrors(validationErrors);
                return;
            }
            // Clear errors if validation passes
            setErrors({});

            let calcolaNumeroLocandine = 0;
            let calcolaNumeroLocandineAggiuntive = 0;
            if (numeroLocandine !== 0 && checkout.piano === 'basic') {
                calcolaNumeroLocandineAggiuntive = numeroLocandine;
            } else if (numeroLocandine === 0 && checkout.piano === 'medium') {
                calcolaNumeroLocandine = 15;
            } else if (numeroLocandine !== 0 && checkout.piano === 'medium') {
                calcolaNumeroLocandine = 15;
                calcolaNumeroLocandineAggiuntive = numeroLocandine;
            } else if (numeroLocandine === 0 && checkout.piano === 'premium') {
                calcolaNumeroLocandine = 30;
            } else if (numeroLocandine !== 0 && checkout.piano === 'premium') {
                calcolaNumeroLocandine = 30;
                calcolaNumeroLocandineAggiuntive = numeroLocandine;
            }
            handleTabChange({d: true}, {
                shape: shape,
                locandina: locandina === "0" && checkout.piano === 'basic' ? null : {
                    temaLocandina: locandina,
                    numeroLocandine: calcolaNumeroLocandine,
                    numeroLocandineAggiuntive: calcolaNumeroLocandineAggiuntive,
                    messaggioPersonalizzato: messaggioPersonalizzato
                }
            }, "qrcode")
        }

    }

    useEffect(() => {
        if (!checkout?.shape) {
            console.log("IFFFF")
            setShape('standard')
            setLocandina("0")
            setNumeroLocandine(0)
            setErrors({});
            setMessaggioPersonalizzato('')
        }
    }, [checkout])
    return (
        <section>
            <div className="how_it_inner" style={{
                backgroundColor: "white",
                padding: "30px"
            }}>
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">Step numero 3</span>
                    </div>
                    <div className="text">
                        <h3>Scegli la forma del qrcode</h3>
                        <p></p>
                    </div>
                    <div className="row" style={{marginBottom: "30px"}}>

                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <StandardSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="standard"
                                               defaultChecked={shape === 'standard'}
                                               checked={shape === 'standard'}
                                               onChange={() => setShape('standard')}
                                        />
                                        <label htmlFor="qrcode"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <AereoSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="aereo"
                                               onChange={() => setShape('aereo')}
                                               defaultChecked={shape === 'aereo'}

                                        />
                                        <label htmlFor="aereo"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <PalloncinoSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="palloncino"
                                               onChange={() => setShape('palloncino')}
                                               defaultChecked={shape === 'palloncino'}
                                        />
                                        <label htmlFor="palloncino"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <BicchiereSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="bicchiere"
                                               onChange={() => setShape('bicchiere')}
                                               defaultChecked={shape === 'bicchiere'}
                                        />
                                        <label htmlFor="bicchiere"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <TortaSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="torta"
                                               onChange={() => setShape('torta')}
                                               defaultChecked={shape === 'torta'}
                                        />
                                        <label htmlFor="torta"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="steps_block">
                                <div className="steps">
                                    <div className="icon">
                                        <CameraSvg/>
                                    </div>
                                    <div>
                                        <input type="radio" id="qrcode" name="qrcode" value="camera"
                                               onChange={() => setShape('camera')}
                                               defaultChecked={shape === 'camera'}
                                        />
                                        <label htmlFor="camera"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {checkout?.piano !== "unico" &&
                        <form>
                            {checkout.piano === 'basic' ?
                                <>
                                    <p>
                                        <strong> Attenzione! </strong>Il pacchetto scelto non prevede locandine. <br/>
                                        Puoi aggiungere al piano basic delle locandine aggiuntive.
                                    </p>
                                    <>
                                        <div className="text">
                                            <h3>Scegli la locandina su cui stampare il qrcode</h3>
                                            <p>
                                                Puoi visualizzare le locandine disponibili &nbsp;
                                                <a href="/banner" target="_blank" rel="noopener noreferrer"
                                                   class="hyperlink">
                                                    cliccando qui.
                                                </a>

                                            </p>
                                        </div>
                                        <div className="form-group-acquisto">
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: isMobile ? "column" : "row"
                                            }}>
                                                <div
                                                    style={{
                                                        minWidth: !isMobile ? "450px" : "none",
                                                        marginTop: "20px",
                                                        marginBottom: "20px"
                                                    }}> Inserisci la tipologia di locandina:
                                                </div>
                                                <select
                                                    className="form-control"
                                                    id="locandine"
                                                    name="locandine"
                                                    onChange={(e) => setLocandina(e.target.value)}
                                                    defaultValue={locandina}
                                                    value={locandina}
                                                >
                                                    <option value="0" disabled>Seleziona una locandina</option>
                                                    <option value="Party">Party</option>
                                                    <option value="Old Rose Floral">Old Rose Floral</option>
                                                    <option value="Green Floral">Green Floral</option>
                                                    <option value="Old Rose Leaves">Old Rose Leaves</option>
                                                    <option value="Gold Leaves">Gold Leaves</option>
                                                </select>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: isMobile ? "column" : "row"
                                            }}>
                                                <div
                                                    style={{
                                                        minWidth: !isMobile ? "450px" : "none",
                                                        marginTop: "20px",
                                                        marginBottom: "20px"
                                                    }}> Messaggio personalizzato da inserire sulla locandina:
                                                </div>
                                                <input
                                                    className="form-control"
                                                    id="messaggioPersonalizzato"
                                                    name="messaggioPersonalizzato"
                                                    placeholder={"Scrivi il tuo messaggio personalizzato"}
                                                    onChange={(e) => setMessaggioPersonalizzato(e.target.value)}
                                                    defaultValue={messaggioPersonalizzato}
                                                    disabled={locandina === "0"}
                                                    value={messaggioPersonalizzato}
                                                >
                                                </input>
                                            </div>
                                            {errors.messaggioPersonalizzato &&
                                                <div style={{color: 'red'}}>{errors.messaggioPersonalizzato}</div>}
                                        </div>
                                        <div className="form-group-2">
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: isMobile ? "column" : "row",
                                                marginTop: "20px",
                                                marginBottom: "20px"

                                            }}>
                                                <div
                                                    style={{
                                                        minWidth: !isMobile ? "450px" : "none",
                                                    }}> Inserisci il numero di locandine:
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="numberLocandine"
                                                    name="numberLocandine"
                                                    defaultValue={numeroLocandine}
                                                    value={numeroLocandine}
                                                    min={"0"}
                                                    step="5"
                                                    disabled={locandina === "0"}
                                                    onChange={(e) => setNumeroLocandine(e.target.value)}
                                                />
                                            </div>
                                            {errors.numeroLocandine &&
                                                <div style={{color: 'red'}}>{errors.numeroLocandine}</div>}
                                            <p>
                                                L'aggiunta di ogni 5 locandine prevede un sovrapprezzo di €5.00 al
                                                prezzo del
                                                pacchetto.
                                            </p>
                                        </div>
                                    </>
                                </>
                                :
                                <>
                                    <div className="text">
                                        <h3>Scegli la locandina su cui stampare il qrcode</h3>
                                        <p>
                                            Puoi visualizzare le locandine disponibili &nbsp;
                                            <a href="/banner" target="_blank" rel="noopener noreferrer"
                                               class="hyperlink">
                                                cliccando qui.
                                            </a>
                                        </p>
                                    </div>
                                    <div className="form-group-acquisto">
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: isMobile ? "column" : "row"
                                        }}>
                                            <div
                                                style={{
                                                    minWidth: !isMobile ? "450px" : "none",
                                                    marginTop: "20px",
                                                    marginBottom: "20px"
                                                }}> Inserisci la tipologia di locandina:
                                            </div>
                                            <select
                                                className="form-control"
                                                id="locandine"
                                                name="locandine"
                                                onChange={(e) => setLocandina(e.target.value)}
                                                defaultValue={locandina}
                                                value={locandina}
                                            >
                                                <option value="0" disabled>Seleziona una locandina</option>
                                                <option value="Party">Party</option>
                                                <option value="Old Rose Floral">Old Rose Floral</option>
                                                <option value="Green Floral">Green Floral</option>
                                                <option value="Old Rose Leaves">Old Rose Leaves</option>
                                                <option value="Gold Leaves">Gold Leaves</option>
                                            </select>
                                        </div>
                                        {errors.locandina && <div style={{color: 'red'}}>{errors.locandina}</div>}
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: isMobile ? "column" : "row"
                                        }}>
                                            <div
                                                style={{
                                                    minWidth: !isMobile ? "450px" : "none",
                                                    marginTop: "20px",
                                                    marginBottom: "20px"
                                                }}> Messaggio personalizzato da inserire sulla locandina:
                                            </div>
                                            <input
                                                className="form-control"
                                                id="messaggioPersonalizzato"
                                                name="messaggioPersonalizzato"
                                                placeholder={"Scrivi il tuo messaggio personalizzato"}
                                                onChange={(e) => setMessaggioPersonalizzato(e.target.value)}
                                                defaultValue={messaggioPersonalizzato}
                                                disabled={locandina === "0"}
                                                value={messaggioPersonalizzato}
                                            >
                                            </input>
                                        </div>
                                        {errors.messaggioPersonalizzato &&
                                            <div style={{color: 'red'}}>{errors.messaggioPersonalizzato}</div>}

                                    </div>
                                    <p>
                                        <strong> Attenzione! </strong>Il pacchetto scelto prevede la realizzazione
                                        di {checkout.piano === "medium" ? 15 : 30} locandine. <br/>
                                        Puoi aggiungere al pacchetto scelto delle locandine aggiuntive:
                                    </p>
                                    <div className="form-group-2">

                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: isMobile ? "column" : "row",
                                            marginTop: "20px",
                                            marginBottom: "20px"

                                        }}>
                                            <div
                                                style={{
                                                    minWidth: !isMobile ? "450px" : "none",
                                                }}> Inserisci il numero di locandine extra:
                                            </div>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="numberLocandine"
                                                name="numberLocandine"
                                                defaultValue={numeroLocandine}
                                                value={numeroLocandine}
                                                min={"0"}
                                                step="5"
                                                disabled={locandina === "0"}
                                                onChange={(e) => setNumeroLocandine(e.target.value)}
                                            />
                                        </div>
                                        <p>
                                            L'aggiunta di ogni 5 locandine prevede un sovrapprezzo di €5.00 al prezzo
                                            del
                                            pacchetto.
                                        </p>
                                    </div>
                                </>
                            }
                        </form>
                    }

                    <div className="row" style={{
                        paddingTop: "30px"
                    }}>
                        <div className="col-lg-5 col-3">
                        </div>
                        <div className="col-lg-2 col-6">

                            <div className="btn_block">
                                <div>
                                    <button onClick={() => onFinish()}
                                            className="btn puprple_btn ml-0">Continua
                                    </button>
                                    <div className="btn_bottom"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-3">
                        </div>
                    </div>

                </div>
            </div>

        </section>

    );
}

export default ConfigureEvent;
