import React, {useEffect} from 'react'
import Header from '../Header/Main'
import Aos from 'aos'
import Steps from "./Steps";
import Footer from "../Footer/Main";

function ProductShop() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, []);

    return (
        <>
            <Header/>
            <Steps/>
            <Footer/>
        </>
    )
}

export default ProductShop