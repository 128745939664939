import * as React from "react"
const PaypalSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_2"
        width={58}
        height={58}
        data-name="Layer 2"
        {...props}
    >
        <defs>
            <mask
                id="mask"
                width={22.505}
                height={6.547}
                x={13}
                y={34.926}
                maskUnits="userSpaceOnUse"
            >
                <path id="mask0" d="M13 34.926h22.505v6.547H13Z" className="cls-1" />
            </mask>
            <mask
                id="mask-2"
                width={18.868}
                height={8.074}
                x={24.433}
                y={34.926}
                maskUnits="userSpaceOnUse"
            >
                <path id="mask1" d="M24.433 34.926H43.3V43H24.433Z" className="cls-1" />
            </mask>
            <style>
                {
                    ".cls-1{fill:#fff}.cls-1,.cls-3,.cls-4{fill-rule:evenodd}.cls-3{fill:#238ec2}.cls-4{fill:#253667}"
                }
            </style>
        </defs>
        <g
            style={{
                mask: "url(#mask)",
            }}
        >
            <path
                d="M33.718 37.133c-.128.846-.774.846-1.4.846h-.356l.249-1.579a.2.2 0 0 1 .194-.166h.163c.426 0 .828 0 1.035.242a.786.786 0 0 1 .115.657Zm-.271-2.207H31.09a.327.327 0 0 0-.324.276l-.953 6.044a.2.2 0 0 0 .194.227h1.209a.23.23 0 0 0 .227-.194l.27-1.713a.327.327 0 0 1 .324-.276h.745a2.421 2.421 0 0 0 2.683-2.241 1.816 1.816 0 0 0-.3-1.521 2.184 2.184 0 0 0-1.718-.602Z"
                className="cls-3"
            />
            <path
                d="M16.908 37.133c-.129.846-.775.846-1.4.846h-.356L15.4 36.4a.2.2 0 0 1 .194-.166h.163c.425 0 .827 0 1.034.242a.786.786 0 0 1 .117.657Zm-.272-2.207h-2.357a.327.327 0 0 0-.323.276L13 41.246a.2.2 0 0 0 .195.227h1.125a.327.327 0 0 0 .323-.277l.258-1.63a.327.327 0 0 1 .323-.276h.746a2.421 2.421 0 0 0 2.682-2.241 1.812 1.812 0 0 0-.3-1.521 2.187 2.187 0 0 0-1.716-.602Z"
                className="cls-4"
            />
        </g>
        <path
            d="M22.108 39.3a1.258 1.258 0 0 1-1.274 1.078.875.875 0 0 1-.935-1.1 1.264 1.264 0 0 1 1.265-1.078.954.954 0 0 1 .753.308.977.977 0 0 1 .191.792Zm1.573-2.2h-1.129a.2.2 0 0 0-.194.166l-.05.316-.079-.115A1.6 1.6 0 0 0 20.9 37a2.6 2.6 0 0 0-2.519 2.27A2.13 2.13 0 0 0 18.8 41a1.768 1.768 0 0 0 1.422.574 2.159 2.159 0 0 0 1.562-.646l-.05.314a.2.2 0 0 0 .194.227h1.016a.329.329 0 0 0 .324-.276l.61-3.864a.2.2 0 0 0-.197-.223Z"
            className="cls-4"
        />
        <path
            d="M38.918 39.3a1.258 1.258 0 0 1-1.273 1.078.875.875 0 0 1-.935-1.1 1.263 1.263 0 0 1 1.265-1.078.952.952 0 0 1 .752.308.97.97 0 0 1 .191.792Zm1.573-2.2h-1.128a.2.2 0 0 0-.194.166l-.05.316-.079-.115A1.6 1.6 0 0 0 37.707 37a2.6 2.6 0 0 0-2.519 2.27 2.13 2.13 0 0 0 .42 1.73 1.766 1.766 0 0 0 1.421.574 2.163 2.163 0 0 0 1.563-.646l-.051.314a.2.2 0 0 0 .194.227h1.017a.327.327 0 0 0 .323-.276l.61-3.864a.2.2 0 0 0-.194-.223Z"
            className="cls-3"
        />
        <g
            style={{
                mask: "url(#mask-2)",
            }}
        >
            <path
                d="M29.691 37.106h-1.134a.327.327 0 0 0-.271.144l-1.565 2.3-.663-2.214a.327.327 0 0 0-.314-.234h-1.115a.2.2 0 0 0-.186.26l1.249 3.666-1.175 1.662a.2.2 0 0 0 .161.31h1.133a.327.327 0 0 0 .269-.141l3.773-5.445a.2.2 0 0 0-.162-.308Z"
                className="cls-4"
            />
            <path
                d="m41.821 35.092-.967 6.154a.2.2 0 0 0 .194.227h.973a.327.327 0 0 0 .323-.277l.954-6.043a.2.2 0 0 0-.194-.227h-1.089a.2.2 0 0 0-.194.166Z"
                className="cls-3"
            />
        </g>
        <path
            d="M35.5 17.977a3.885 3.885 0 0 0-.824-3.494c-.906-1.032-2.543-1.475-4.637-1.475H23.96a.87.87 0 0 0-.859.734L20.57 29.791a.522.522 0 0 0 .516.6h3.752l-.259 1.643a.456.456 0 0 0 .451.527h3.163a.76.76 0 0 0 .751-.642l.031-.161.6-3.778.038-.209a.762.762 0 0 1 .752-.642h.473c3.064 0 5.463-1.245 6.164-4.845a4.125 4.125 0 0 0-.633-3.642 3.021 3.021 0 0 0-.869-.665Z"
            className="cls-3"
        />
        <path
            d="M35.5 17.977a3.885 3.885 0 0 0-.824-3.494c-.906-1.032-2.543-1.475-4.637-1.475H23.96a.87.87 0 0 0-.859.734L20.57 29.791a.522.522 0 0 0 .516.6h3.752l.943-5.977-.03.188a.866.866 0 0 1 .856-.734h1.784c3.5 0 6.245-1.423 7.046-5.538.024-.119.044-.237.063-.353Z"
            style={{
                fill: "#20274f",
                fillRule: "evenodd",
            }}
        />
        <path
            d="M26.793 18a.759.759 0 0 1 .752-.642h4.764a10 10 0 0 1 1.572.114c.137.022.271.048.4.076s.256.062.378.1l.18.056a4.236 4.236 0 0 1 .659.279 3.885 3.885 0 0 0-.824-3.494c-.906-1.032-2.543-1.475-4.637-1.475H23.96a.87.87 0 0 0-.859.734L20.57 29.791a.522.522 0 0 0 .516.6h3.752Z"
            className="cls-4"
        />
    </svg>
)
export default PaypalSvg
