import React from 'react'
import { Link } from 'react-router-dom'
import moment from "moment/moment";

function Banner({event}) {

    return (
        <>
            <section className="title_event_section">
                <div className="container">
                    <div className="row">

                        <div className="banner_text">
                            <div className="top_part">
                                <Link style={{fontSize:"25px"}} to="/profile-page" className="back_btn">
                                    <i  className="icofont-arrow-left"/> Ritorna agli acquisti
                                </Link>
                            </div>
                            <h1>
                                Nome evento : <span> {event?.custom?.name}</span>
                            </h1>
                            <h2>
                                <b>{moment(event?.custom?.dateEvent?.split("T")[0]).format("DD/MM/YYYY")} - {moment(event?.custom?.dateEventFinish?.split("T")[0]).format("DD/MM/YYYY")}</b>
                            </h2>

                        </div>



                    </div>
                </div>
            </section>


        </>
    )
}

export default Banner