import React, { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import {env} from "../../../configs/EnvironmentConfig";
import Header from "../../Header/Main";
import Loading from "../../../shared-components/Loading";
import Footer from "../../Footer/Main";

function Main() {
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const fetchTokens = async (code) => {

            const requestBody = new URLSearchParams({
                grant_type: 'authorization_code',
                client_id: env.LOGIN.cognitoConfig.ClientId,
                client_secret:env.LOGIN.cognitoConfig.GoogleAppClientSecret,
                code: code,
                redirect_uri: env.LOGIN.app_host+'/auth/callback'
            });

            try {
                const response = await fetch(`${env.LOGIN.cognitoConfig.CognitoDomain}/oauth2/token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',

                    },
                    body: requestBody
                });
                const data = await response.json();
                if (!response.ok) {
                    console.error('Error fetching google token /oauth2/token : ', data);
                    return;
                }
                console.log("ale-debug data",data)
                localStorage.setItem('idToken', data.id_token);
                localStorage.setItem('accessToken', data.access_token);
                localStorage.setItem('refreshToken', data.refresh_token);
                localStorage.setItem('login_type', 'google');
                navigate(env.LOGIN.profile_page);


            } catch (error) {
                console.error('Error fetching google token catch principale : ',error);
                navigate('/sign-in')
            }
        };

        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        if (code) {
            fetchTokens(code).then(r => console.log(""))
        }else{
            navigate('/sign-in')
        }
    }, []);


    return  (
        <div>
            <Header />
            <Loading minHeight={"100vh"}/>
            <Footer/>

        </div>
    )
}

export default Main