import React from 'react'
import Header from '../Header/Main'
import Footer from '../Footer/Main'
import Purchases from "../Menprofilepage/Purchases";
import Account from "../Menprofilepage/Account";


function Main() {

  return (
      <>
          <Header />
          <Account />
          <Purchases/>
          <Footer />
      </>
  )
}

export default Main