import React, {useState} from 'react'
import ConfigureEvent from "./ConfigureEvent";
import ConfigureQRCODE from "./ConfigureQRCODE";
import ConfigureSpedizione from "./ConfigureSpedizione";
import Swal from 'sweetalert2'
import SelectPlanSingolo from "./SelectPlanSingolo";

function Steps() {

    const [tabMenu, setTabMenu] = useState({a: true, b: false, c: false, d: false, e: false});
    const [formCompleted, setFormCompleted] = useState({a: false, b: false, c: false, d: false, e: false});
    const [checkout, setCheckout] = useState([]);


    const handleTabChange = (nextTab, currentConfiguration, key) => {
        console.log(currentConfiguration)
        if (currentConfiguration !== null) {
            const currentTab = Object.keys(tabMenu).find(key => tabMenu[key]);
            if (checkout !== undefined && checkout.length !== 0 && currentConfiguration !== undefined &&
                currentConfiguration.piano !== undefined && currentConfiguration?.piano !== checkout[0]?.piano) {
                console.log("IF")

                Swal.fire({
                    title: "Vuoi cambiare pacchetto",
                    text: "Tutte le configurazioni verranno annullate!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, conferma!",
                    cancelButtonText: "Annulla"
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: "Confermato!",
                            text: "Ricomincia con la configurazione",
                            icon: "success"
                        });


                        setCheckout(prevCheckout => {

                            const updatedCheckout = [...prevCheckout];

                            if (updatedCheckout.length === 0) {
                                updatedCheckout.push({});
                            }


                            // Aggiorna l'oggetto all'indice 0 con la nuova configurazione
                            updatedCheckout[0] = {
                                ...currentConfiguration,
                                reset: true
                            };

                            console.log(updatedCheckout)
                            return updatedCheckout;
                        });

                        setFormCompleted({[currentTab]: true});
                        setTabMenu(nextTab);
                    }
                });
            } else {

                setCheckout(prevCheckout => {

                    const updatedCheckout = [...prevCheckout];

                    if (updatedCheckout.length === 0) {
                        updatedCheckout.push({});
                    }


                    // Aggiorna l'oggetto all'indice 0 con la nuova configurazione
                    updatedCheckout[0] = {
                        ...updatedCheckout[0],
                        ...currentConfiguration
                    };

                    console.log(updatedCheckout)
                    return updatedCheckout;
                });

                setFormCompleted({...formCompleted, [currentTab]: true});

                setTabMenu(nextTab);
            }

        } else {
            console.log(nextTab);
            console.log(tabMenu);
            setTabMenu(nextTab);
        }
        // Scroll to top
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <>
            <section className="row_am faq_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">Fase di acquisto</span>
                    </div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist" data-aos="fade-up" data-aos-duration={1500}>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${tabMenu.a && 'active'}`}
                                onClick={() => handleTabChange({a: true}, null, null)}
                                id="genral-tab"
                                data-toggle="tab"
                                data-target="#genral"
                                type="button"
                                role="tab"
                                aria-controls="genral"
                                aria-selected="true"
                            >
                                1 - Scegli il pacchetto
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${tabMenu.b && 'active'}`}
                                onClick={() => formCompleted.a && handleTabChange({b: true}, null, null)}
                                id="pricing-tab"
                                data-toggle="tab"
                                data-target="#pricing"
                                type="button"
                                role="tab"
                                aria-controls="pricing"
                                aria-selected="false"
                                disabled={!formCompleted.a}
                            >
                                2 - Crea il tuo evento
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${tabMenu.c && 'active'}`}
                                onClick={() => formCompleted.b && handleTabChange({c: true}, null, null)}
                                id="account-tab"
                                data-toggle="tab"
                                data-target="#account"
                                type="button"
                                role="tab"
                                aria-controls="account"
                                aria-selected="false"
                                disabled={!formCompleted.b}
                            >
                                3 - Scegli il QRCode
                            </button>
                        </li>
                        {/*<li className="nav-item" role="presentation">*/}
                        {/*    <button*/}
                        {/*        className={`nav-link ${tabMenu.d && 'active'}`}*/}
                        {/*        onClick={() => formCompleted.c && handleTabChange({d: true})}*/}
                        {/*        id="returns-tab"*/}
                        {/*        data-toggle="tab"*/}
                        {/*        data-target="#returns"*/}
                        {/*        type="button"*/}
                        {/*        role="tab"*/}
                        {/*        aria-controls="returns"*/}
                        {/*        aria-selected="false"*/}
                        {/*        disabled={!formCompleted.c}*/}
                        {/*    >*/}
                        {/*        4 - Crea un contest*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${tabMenu.e && 'active'}`}
                                onClick={() => formCompleted.d && handleTabChange({e: true})}
                                id="support-tab"
                                data-toggle="tab"
                                data-target="#support"
                                type="button"
                                role="tab"
                                aria-controls="support"
                                aria-selected="false"
                                disabled={!formCompleted.d}
                            >
                                4 - Completa il pagamento
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent" data-aos="fade-up" data-aos-duration={1500}>
                        <div className={`tab-pane fade ${tabMenu.a && 'show active'}`} id="genral" role="tabpanel"
                             aria-labelledby="genral-tab">
                            <SelectPlanSingolo handleTabChange={handleTabChange}/>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.b && 'show active'}`} id="pricing" role="tabpanel"
                             aria-labelledby="pricing-tab">
                            <ConfigureEvent checkout={checkout[0]} handleTabChange={handleTabChange}/>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.c && 'show active'}`} id="account" role="tabpanel"
                             aria-labelledby="account-tab">
                            {checkout[0]?.piano &&
                                <ConfigureQRCODE checkout={checkout[0]} handleTabChange={handleTabChange}/>
                            }
                        </div>
                        {/*<div className={`tab-pane fade ${tabMenu.d && 'show active'}`} id="returns" role="tabpanel"*/}
                        {/*     aria-labelledby="returns-tab">*/}
                        {/*    <ConfigureFotoVideo handleTabChange={handleTabChange}/>*/}
                        {/*</div>*/}
                        <div className={`tab-pane fade ${tabMenu.e && 'show active'}`} id="support" role="tabpanel"
                             aria-labelledby="support-tab">
                            <ConfigureSpedizione checkout={checkout[0]}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Steps