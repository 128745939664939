import React from 'react'
import {Link} from 'react-router-dom'
import Logo from '../../assets/images/logobianco.png'
import AmericanExpressSvg from "./AmericanExpressSvg";
import ApplePaySvg from "./ApplePaySvg";
import GooglePaySvg from "./GooglePaySvg";
import MastercardSvg from "./MastercardSvg";
import PaypalSvg from "./PaypalSvg";

function Main() {
    return (
        <>
            <footer className="white_text">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="logo_side">
                                <div className="log">
                                    <Link to="/">
                                        <img src={Logo} alt="Logo" style={{width: "35vh"}}/>
                                    </Link>
                                </div>
                                {/* <div className="news_letter">*/}
                                {/*    <h3>Subscribe newsletter</h3>*/}
                                {/*    <p>Be the first to recieve all latest post in your inbox</p>*/}
                                {/*    <form>*/}
                                {/*        <div className="form-group">*/}
                                {/*            <input  type="email" className="form-control" placeholder="Enter your email" />*/}
                                {/*            <button className="btn">*/}
                                {/*                <i className="icofont-paper-plane" />*/}
                                {/*            </button>*/}
                                {/*        </div>*/}
                                {/*        <p className="note">*/}
                                {/*            By clicking send link you agree to receive message.*/}
                                {/*        </p>*/}
                                {/*    </form>*/}
                                {/*</div>*/}
                                <ul className="contact_info">
                                    <li>
                                        <Link to="mailto:">info@maffle.it</Link>
                                    </li>
                                    <li>
                                        <Link to="tel:+393791785539">+393791785539</Link>
                                    </li>
                                </ul>
                                <ul className="social_media">
                                    <li>
                                        <Link to="https://www.facebook.com/profile.php?id=61560815113854">
                                            <i className="icofont-facebook"/>
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/">
                                            <i className="icofont-twitter" />
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="https://www.instagram.com/snapmemoriesit/">
                                            <i className="icofont-instagram"/>
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/">
                                            <i className="icofont-pinterest" />
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="download_side">
                                <ul className="contact_info">
                                    <li>
                                        <Link to="/">
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/come-funziona">
                                            How it works
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/banner">
                                            Locandine
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">
                                            Contattaci
                                        </Link>
                                    </li>
                                </ul>
                                <ul className="contact_info">
                                    <li>
                                        <ApplePaySvg/>
                                        <GooglePaySvg/>
                                        <MastercardSvg/>
                                        <PaypalSvg/>
                                        <AmericanExpressSvg/>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="ft_inner">
                            <div className="copy_text">
                                <p>© Copyrights 2024. All rights reserved.</p>
                            </div>
                            {/* <ul className="links">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About us</Link>
                                </li>
                                <li>
                                    <Link to="/pricing">Pricing</Link>
                                </li>
                                <li>
                                    <Link to="/blog-list">Blog</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact us</Link>
                                </li>
                            </ul> */}
                            <div className="design_by">
                                <p>
                                    Crafted by{" "}
                                    <a href="https://www.maffle.it">
                                        Maffle Software Consulting
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Main