import React, {useContext, useState} from "react";
import Bluedots from '../../assets/images/blue_dotes.png'
import {Tooltip} from "react-tooltip";
import axios from "axios";
import {env} from "../../configs/EnvironmentConfig";
import {AuthContext} from "../../configs/AuthContext";
import Swal from 'sweetalert2'
import Loading from "../../shared-components/Loading";

function CustomizeEvent({event}) {

    const {user} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [download, setDownload] = useState([]);


    const getZip = async (event) => {
        console.log(event)
        try {
            setLoading(true);

            let body = {
                "bucket": env.S3_BUCKET,
                "folder": "alta-risoluzione/" + event.custom.id + "/",
                "id": event.checkoutSessionId,
                "type": "linkZipS3",
                "progetto": "SNAPMEMORIES",
            }
            const response = await axios.post(`${env.API_ENDPOINT_SERVER_MAFFLE}/maffle/s3/downloadZip`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
            if (response.data.esito === "OK") {
                setDownload(response.data.risultato);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Errore!",
                    text: response.data.descrizioneErrore,
                });
                setDownload([]);
            }
        } catch (err) {
            setDownload([]);
            console.error("err: ", err.message);
        } finally {
            setLoading(false);
        }
    }


    const downloadFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'evento-snapmemories.zip'); // Nome file per il download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Rimuovi il link dopo il download
    };

    return (
        <>

            <section className="detail_info_section ">
                <div className="container">

                    <div className="testimonial_inner">
                        <div className="dotes_blue">
                            <img src={Bluedots} alt="image"/>
                        </div>
                        <div className="container">
                            <div className="testimonial_slides">

                                <div className="testimonial_box">
                                    <div className="testi_text">
                                        <p>
                                            Personalizza il tuo evento
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="client_logo_slider">
                                <div data-tooltip-id="categoria-tooltip" className="img" style={{cursor: "pointer"}}>
                                    <div>
                                        <b>CATEGORIA</b>
                                    </div>
                                    <div>
                                        {event?.custom?.categoriaEvento?.toUpperCase()}
                                    </div>
                                </div>
                                <Tooltip id="categoria-tooltip">
                                    <span>Funzionalità non disponibile</span><br/>
                                    <span>Contattaci per modificare la categoria del tuo evento</span>
                                </Tooltip>
                                <div data-tooltip-id="tema-tooltip" className="img" style={{cursor: "pointer"}}>
                                    <div>
                                        <b>TEMA</b>
                                    </div>
                                    <div>
                                        {event?.custom?.theme?.toUpperCase()}
                                    </div>
                                </div>
                                <Tooltip id="tema-tooltip">
                                    <span>Funzionalità non disponibile</span><br/>
                                    <span>Contattaci per modificare il tema del tuo evento</span>
                                </Tooltip>
                            </div>
                            {loading ?
                                <Loading/>

                                :

                                <div className="client_logo_slider">
                                    {download.length === 0 ?
                                        event?.custom?.linkZipS3 === undefined ?
                                            <div className="btn_block" style={{marginTop: "20px"}}
                                                 onClick={() => getZip(event)}>
                                                <div style={{fontSize: "20px"}} className="btn puprple_btn ml-0">
                                                    Ottieni lo zip del tuo evento
                                                </div>
                                                <div className="btn_bottom"/>
                                            </div>
                                            :
                                            event?.custom?.linkZipS3 === "IN CORSO" ?
                                                <div data-tooltip-id="categoria-tooltip" className="img"
                                                     style={{width: "auto", height: "auto", marginTop: "20px"}}>
                                                    <div>
                                                        Il file è in corso di elaborazione.
                                                        <br/>Riceverai una mail al termine dell'operazione.
                                                    </div>
                                                </div>
                                                :
                                                <div className="btn_block" style={{marginTop: "20px"}}
                                                     onClick={() => downloadFile(event?.custom?.linkZipS3)}>
                                                    <div style={{fontSize: "20px"}}
                                                         className="btn puprple_btn ml-0">
                                                        Download zip
                                                    </div>
                                                    <div className="btn_bottom"/>
                                                </div>
                                        :
                                        <div data-tooltip-id="categoria-tooltip" className="img"
                                             style={{width: "auto", height: "auto", marginTop: "20px"}}>
                                            <div>
                                                Il file è in corso di elaborazione.
                                                <br/>Riceverai una mail al termine dell'operazione.
                                            </div>
                                        </div>

                                    }
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CustomizeEvent