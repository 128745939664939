import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import Header from "../Header/Main";
import Footer from '../Footer/Main'
import {env} from "../../configs/EnvironmentConfig";
import {PuffLoader} from "react-spinners";
import success from "../../assets/images/snapmemories/success.gif";
import Mailicon from "../../assets/images/mail_icon.png";
import Phoneicon from "../../assets/images/phone_icon.png";
import whatsappicon from "../../assets/images/snapmemories/whatsapp.png";

const qs = require("qs");

const SuccessPage = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const sessionId = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    }).sessionId;

    //state
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sessionId !== undefined
            ? buy(sessionId)
            : navigate('/result-page');
    }, []);

    async function buy(sessionId) {


        try {
            setLoading(true)

            // const token = sessionStorage.getItem("token")
            const response = await axios.post(env.API_ENDPOINT_SERVER_MAFFLE + "/maffle/create-purchase-snapmemories", {
                    sessionId: sessionId
                }
            )

            console.log("RESPONSE", response);
            if (response.data.esito === "KO") {
                navigate(`/result-page?canceled=true`);
            }

            setLoading(false);

        } catch (e) {
            console.error("eccezione", e);
            navigate(`/result-page?canceled=true`);
        }
    }


    return (
        <>
            <Header/>
            <section className="success-page">
                <div className={"container-card"}>
                    {loading && <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                        }}
                    ><PuffLoader/></div>}
                    {!loading && (
                        <div style={{padding: "23px"}}>
                            <div className="card" style={{borderRadius: "20px"}}>
                                <div className="form_block" style={{display: 'block'}}>
                                    <div className="form_side"
                                         style={{
                                             padding: "15px",
                                             width: "100%",
                                             textAlign: "center"
                                         }}>
                                        <img src={success} alt="image" style={{width: "35vh"}}/>
                                        <div className="section_title" style={{textAlign: "center", marginTop: "30px"}}>
                                            <h2>
                                                Pagamento avvenuto con <span>successo!</span>
                                            </h2>
                                            <p>Riceverai una mail con il riepilogo del tuo ordine.</p>
                                            <br/>
                                            <p>Contattaci per qualsiasi informazione:</p>

                                        </div>
                                        <ul className="contact_listing">
                                            <li>
                                            <span className="icon">
                                                <img src={Mailicon} alt="image"/>
                                            </span>
                                                <span className="lable">Inviaci una mail</span>
                                                <Link to="mailto:info@maffle.it">info@maffle.it</Link>
                                            </li>
                                            <li>
                                            <span className="icon">
                                                <img src={Phoneicon} alt="image"/>
                                            </span>
                                                <span className="lable">Chiamaci</span>
                                                <Link to="tel:3791785539">+393791785539</Link>
                                            </li>
                                            <li>
                                        <span className="icon">
                                            <img src={whatsappicon} alt="image" style={{
                                                width: '7vh'
                                            }}/>
                                              </span>
                                                <span className="lable">Whatsapp</span>
                                                <Link
                                                    to="https://wa.me/+393791785539?text=Sarei%20interessato%20all'%20acquisto%20di%20un%20QRcode">Avvia
                                                    la chat
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}

                </div>
            </section>
            <Footer/>
        </>
    );
};

export default SuccessPage;
