import React, {useContext, useEffect, useState} from 'react'
import Header from '../Header/Main'
import Footer from '../Footer/Main'
import CustomizeEvent from "../Meneventpage/CustomizeEvent";
import InfoEvent from "../Meneventpage/InfoEvent";
import {AuthContext} from "../../configs/AuthContext";
import axios from "axios";
import {env} from "../../configs/EnvironmentConfig";
import { useParams } from 'react-router-dom';
import UpdateProfile from "../Meneventpage/UpdateProfile";
import InfoBanner from "../Meneventpage/InfoBanner";
import Banner from "../Meneventpage/Banner";
import Loading from "../../shared-components/Loading";




function Main() {
    const { user} = useContext(AuthContext)
    const [loading,setLoading] = useState(true)
    const [event,setEvent] = useState(null)
    const { id } = useParams();
    const fetchData = async () => {
        try {
            const response = await axios.get(`${env.API_ENDPOINT_SERVER_MAFFLE}/maffle/get-single-purchase?id=${id}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if(response.data.esito==="OK"){
                setEvent(response.data.risultato)
            }else{
                setEvent(null)
            }
        } catch (err) {
            setEvent(null)
            console.error("err: ",err.message)
        } finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    if(loading){
        return  (
            <div>
                <Header />
                <Loading minHeight={"100vh"}/>
                <Footer/>

            </div>
        )
    }
    if(!loading && event!==null) {
        return (
            <>
                <Header />
                <Banner event={event}/>
                <InfoEvent event={event}/>
                <CustomizeEvent event={event}/>
                <UpdateProfile event={event} reloadEvent={fetchData}/>
                <InfoBanner event={event}/>
                <Footer/>
            </>
        )
    }
}

export default Main