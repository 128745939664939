import React from 'react'
import {Link} from 'react-router-dom'
import Mailicon from '../../assets/images/mail_icon.png'
import Phoneicon from '../../assets/images/phone_icon.png'
import whatsappicon from '../../assets/images/snapmemories/whatsapp.png'

function Contactblock() {
    return (
        <>
            <section className="contact_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">Contattaci</span>
                        <h2>
                            Vorresti sapere come ottenere un QRcode per il tuo evento? <span>Contattaci</span>
                        </h2>
                        <p>
                            Per qualsiasi domanda tu abbia su SnapMemories puoi compilare il seguente form e ti
                            risponderemo in breve tempo.
                        </p>
                    </div>
                    <ul className="contact_listing">
                        <li data-aos="fade-up" data-aos-duration={1500}>
                            <span className="icon">
                                <img src={Mailicon} alt="image"/>
                            </span>
                            <span className="lable">Inviaci una mail</span>
                            <Link to="mailto:info@maffle.it">info@maffle.it</Link>
                        </li>
                        <li data-aos="fade-up" data-aos-duration={1500} data-aos-delay={150}>
                            <span className="icon">
                                <img src={Phoneicon} alt="image"/>
                            </span>
                            <span className="lable">Chiamaci</span>
                            <Link to="tel:3791785539">+393791785539</Link>
                        </li>
                        <li data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
                            <span className="icon">
                                <img src={whatsappicon} alt="image" style={{
                                    width: '7vh'
                                }}/>
                            </span>
                            <span className="lable">Whatsapp</span>
                            <Link
                                to="https://wa.me/+393791785539?text=Sarei%20interessato%20all'%20acquisto%20di%20un%20QRcode">Avvia
                                la chat
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Contactblock