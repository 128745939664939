import React, { useEffect, useState } from "react";
import { confirmSignUp } from "../../../configs/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

export default function ConfirmSignUp() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const email = new URLSearchParams(location.search).get('email');
        if (!email) {
            navigate('/sign-in');
        }
        setEmail(email);
    }, [location, navigate]);

    const validationSchema = Yup.object({
        code: Yup.number()
            .typeError("Il codice deve essere un numero")
            .required("Il codice di verifica è obbligatorio")
            .integer("Il codice deve essere un numero intero"),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        setError("");
        try {
            await confirmSignUp(email, values.code.toString());
            navigate('/sign-in?verification=true');
        } catch (err) {
            setError(err.message);
        }
        setSubmitting(false);
    };

    return (
        <div className="page_wrapper">
            <div className="full_bg">
                <section className="signup_section">
                    <div className="container">
                        <div className="top_part">
                            <Link style={{fontSize:"25px"}} to="/login" className="back_btn">
                                <i className="icofont-arrow-left" /> Torna al login
                            </Link>
                        </div>
                        <div className="form_block">
                            <div className="form_side" style={{width:"100%"}}>
                                <div className="section_title"></div>
                                <span className="title_badge">Verifica il tuo account</span>
                                <h2>
                                    <span>Inserisci il codice</span> ricevuto tramite mail
                                </h2>

                                <Formik
                                    initialValues={{ code: "" }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <div className="email_display">
                                                    <label>Email</label>
                                                    <div className="form-control-static">{email}</div>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="code"
                                                    placeholder="Codice di conferma"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.code}
                                                />
                                                {touched.code && errors.code && (
                                                    <div className="error">{errors.code}</div>
                                                )}
                                            </div>
                                            {error && <p className="error-2">{error}</p>}
                                            <div className="btn_block">
                                                <button
                                                    type="submit"
                                                    className="btn puprple_btn ml-0"
                                                    disabled={isSubmitting}
                                                >
                                                    Invia
                                                </button>
                                                <div className="btn_bottom" />
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
