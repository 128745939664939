import React, {useContext} from 'react'
import Dots from '../../assets/images/blue_dotes.png'
import {AuthContext} from "../../configs/AuthContext";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {forgotPassword} from "../../configs/auth";
import {useNavigate} from "react-router-dom";
function Account() {
    const { user, signOut} = useContext(AuthContext)
    const navigate = useNavigate();
    const changePassword = async () => {
        try {
            await forgotPassword(user.email);
            navigate('/reset-password?page=profilePage&username=' + user.email);
        }
        catch (e) {
            console.error(e)
        }
    }
    return (
        <>
            <section className="account_section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section_title">
                                <span style={{fontSize:"20px"}} className="title_badge">Bentornato</span>
                                <h2>
                                    {user.name}
                                </h2>
                                <div>
                                    {/*<h3 style={{fontSize: "25px"}}><b> Email</b></h3>*/}
                                    <h4 style={{wordWrap: "break-word"}}>  {user.email} </h4>
                                </div>
                                {/* <div>
                                    {localStorage.getItem("login_type") === "google" ?
                                        <img style={{width: "6%"}} src={google} alt="image"/>
                                        :
                                        <img style={{width: "6%"}} src={cognito} alt="image"/>
                                    }
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mail_block white_text" style={{height:"100%"}}>
                                <div className="dotes_blue">
                                    <img src={Dots} alt="image" />
                                </div>
                                <div className="text">
                                    <div className="handle-account">
                                        <h3>Gestisci il tuo account</h3>

                                        {/*{localStorage.getItem("login_type") === "google" ?
                                            <img style={{width: "10%"}} src={google} alt="image"/>
                                            :
                                            <img style={{width: "10%"}} src={cognito} alt="image"/>
                                        }*/}
                                    </div>
                                    <div className="handle-account-buttons">
                                        <div className="button-container">
                                            <button
                                                className="btn"
                                                onClick={()=>changePassword()}
                                                data-tooltip-id="password-tooltip"
                                                data-tooltip-content={localStorage.getItem("login_type") === "google" ? "Account gestito da Google" : "Cambia Password"}
                                                disabled={localStorage.getItem("login_type") === "google"}
                                            >
                                                <i className="icofont-ui-password"/>
                                            </button>
                                            <Tooltip id="password-tooltip"/>
                                            <p className="button-text">Cambia Password</p>
                                        </div>

                                        <div className="button-container">
                                            <button
                                                className="btn"
                                                onClick={() => signOut()}
                                                data-tooltip-id="logout-tooltip"
                                                data-tooltip-content="Logout"
                                            >
                                                <i className="icofont-logout"/>
                                            </button>
                                            <Tooltip id="logout-tooltip"/>
                                            <p className="button-text">Logout</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Account