import React from "react";
import { Link } from 'react-router-dom'
import Default from '../../assets/images/snapmemories/profile-page/banner/default.png'
import OldRoseLeaves from '../../assets/images/snapmemories/profile-page/banner/Old Rose Leaves.png'
import OldRoseFloral from '../../assets/images/snapmemories/profile-page/banner/Old Rose Floral.png'
import GreenFloral from '../../assets/images/snapmemories/profile-page/banner/Green Floral.png'
import GoldLeaves from '../../assets/images/snapmemories/profile-page/banner/Gold Leaves.png'
import Party from '../../assets/images/snapmemories/profile-page/banner/Party.png'
import {Tooltip} from "react-tooltip";


function InfoEvent({event}) {

    const getBannerImg = (name) => {
        switch (name) {
            case 'Old Rose Leaves':
                return OldRoseLeaves;
            case 'Old Rose Floral':
                return OldRoseFloral;
            case 'Green Floral':
                return GreenFloral;
            case 'Gold Leaves':
                return GoldLeaves;
            case 'Party':
                return Party;
            default:
                return Default;
        }
    };
    const getBannerPlan = (plan) => {
        switch (plan) {
            case 'basic':
                return 0;
            case 'medium':
                return 15;
            case 'premium':
                return 30;
            default:
                return 0;
        }
    };

    return (
        <>
            <section className="row_am detail_event_second_section about_service">
                <div className="container">

                    {event?.custom?.locandina!==undefined && event?.custom?.locandina!==null &&
                        <div className="row service_blocks">
                            <div className="col-md-6">
                                <div className="service_text">
                                    <div className="service_badge">
                                        <i className="icofont-gear"/>
                                        <span style={{fontSize:"20px"}}>Locandina</span>
                                    </div>
                                    <h2>
                                        <span>{event?.custom?.locandina?.temaLocandina}</span>
                                    </h2>

                                    <ul className="feature_list" style={{marginBottom: "20px", fontSize: "20px"}}>
                                        <li>
                                            <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                            </div>
                                            <div className="text">
                                                <p><b>Numero locandine comprese nel
                                                    piano:</b> {getBannerPlan(event?.custom?.type)}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                            </div>
                                            <div className="text">
                                                <p><b>Numero locandine
                                                    extra:</b> {event?.custom?.locandina?.numeroLocandineAggiuntive}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                            </div>
                                            <div className="text">
                                                <p><b>Messaggio personalizzato
                                                    </b> {event?.custom?.locandina?.messaggioPersonalizzato}</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <div style={{fontSize: "20px"}}>
                                        <p><b>Indirizzo di spedizione</b></p>
                                        <p>{event?.custom?.indirizzoSpedizione?.nome}{" "}{event?.custom?.indirizzoSpedizione?.cognome}</p>
                                        <p>{event?.custom?.indirizzoSpedizione?.via}{", "}{event?.custom?.indirizzoSpedizione?.ncivico}</p>
                                        <p>{event?.custom?.indirizzoSpedizione?.cap}{" "}
                                            {event?.custom?.indirizzoSpedizione?.citta}{ " ("}{event?.custom?.indirizzoSpedizione?.prov}{")"}</p>
                                        <p>{event?.custom?.indirizzoSpedizione?.telefono}</p>
                                    </div>
                                    <div
                                        data-tooltip-id="spedizione-tooltip"

                                        className={`btn_block ${true ? "disabled" : ""}`}>
                                        <Link
                                            style={{fontSize:"20px"}}
                                            to="/"
                                            className="btn puprple_btn ml-0"

                                        >
                                            Verifica stato di spedizione
                                        </Link>
                                        <Tooltip id="spedizione-tooltip">
                                            <span>Funzionalità non disponibile</span><br/>
                                            <span>Contattaci per verificare lo stato della tua spedizione</span>
                                        </Tooltip>
                                        <div className="btn_bottom"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="img video_player">
                                    <img src={getBannerImg(event?.custom?.locandina?.temaLocandina)} alt="image"/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>

        </>
    )
}

export default InfoEvent