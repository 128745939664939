import * as React from "react"
const AmericanExpressSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={23}
        fill="none"
        {...props}
    >
        <path
            fill="#016FD0"
            d="M0 3.795a3 3 0 0 1 3-3h30a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-16Z"
        />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M27.621 12.284v1.514h-3.753v1.052h3.663v1.514h-3.663v1.034h3.753v1.532l3.042-3.323-3.042-3.323Zm-.99-5.668-1.26-2.944h-3.6l-3.69 8.603h2.997v7.634l9.252.009 1.449-1.662 1.467 1.662H36V17.49l-1.728-1.901L36 13.696v-2.39l-1.737.009V6.718l-1.629 4.597H31.05L29.376 6.7v4.615h-3.78l-.54-1.348h-2.97l-.54 1.348h-1.998l2.907-6.71v-.01h2.295l2.871 6.655V4.595l2.79.01 1.44 4.125 1.458-4.135H36v-.923h-3.393l-.765 2.206-.765-2.206h-4.446v2.944Zm-4.554 5.64v6.711h5.544v-.009h2.286l1.89-2.141 1.908 2.141H36v-.092l-3.006-3.259L36 12.303v-.046h-2.268l-1.89 2.123-1.872-2.123h-7.893Zm.63-3.793.864-2.133.873 2.133h-1.737Z"
            clipRule="evenodd"
        />
    </svg>
)
export default AmericanExpressSvg
