import React, { useState } from "react";
import { Link } from 'react-router-dom'
import Default from '../../assets/images/snapmemories/profile-page/banner/default.png'
import OldRoseLeaves from '../../assets/images/snapmemories/profile-page/banner/Old Rose Leaves.png'
import OldRoseFloral from '../../assets/images/snapmemories/profile-page/banner/Old Rose Floral.png'
import GreenFloral from '../../assets/images/snapmemories/profile-page/banner/Green Floral.png'
import GoldLeaves from '../../assets/images/snapmemories/profile-page/banner/Gold Leaves.png'
import Party from '../../assets/images/snapmemories/profile-page/banner/Party.png'
import moment from "moment";
import {env} from "../../configs/EnvironmentConfig";


function InfoEvent({event}) {

    const downloadQr = async (urlQr) => {
        try {
            const key=urlQr.split('/').slice(3).join('/')
            const response = await fetch(`${env.API_ENDPOINT_SERVER_MAFFLE}/maffle/snapmemories/downloadObject?key=${key}`, {
                method: 'GET',
                headers: {
                    'Accept': 'image/jpeg',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = "qr_code.png"
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download error:', error);
        }
    };
    return (
        <>
            <section style={{padding:"25px 0"}} className="row_am detail_event_second_section about_service">
                <div className="container">

                    <div className="row service_blocks flex-row-reverse">
                        <div className="col-md-6">
                            <div className="service_text right_side">
                                <div className="service_badge">
                                    <i className="icofont-ui-messaging"/>
                                    <span style={{fontSize:"20px"}}>Informazioni generali</span>
                                </div>
                                <h2>
                                    <span>{event?.totaleSpesoConIva}€</span>
                                </h2>

                                <ul className="feature_list" style={{fontSize: "20px"}}>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p><b>Id evento:</b> {event?.custom?.id}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p><b>Data di acquisto:</b> {event?.dataAcquisto}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p><b>Email di pagamento:</b> {event?.emailPagamento}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p><b>Nome evento:</b> {event?.custom?.name}</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p><b>Data inizio
                                                evento:</b> {moment(event?.custom?.dateEvent?.split("T")[0]).format("DD/MM/YYYY")}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p><b>Data fine
                                                evento:</b> {moment(event?.custom?.dateEventFinish?.split("T")[0]).format("DD/MM/YYYY")}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p><b>Piano:</b> {event?.custom?.type}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p><b>Tema:</b> {event?.custom?.theme}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled"/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p><b>Categoria:</b> {event?.custom?.categoriaEvento}</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn_block" onClick={() => downloadQr(event?.custom?.qrcode)}>
                                    <div style={{fontSize: "20px"}} className="btn puprple_btn ml-0">
                                        Scarica QR code digitale
                                    </div>
                                    <div className="btn_bottom"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="img" style={{padding:"25px"}}>
                                <img src={event?.custom?.qrcode} alt="image"/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}

export default InfoEvent