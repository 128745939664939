import React, {useState} from 'react';
import DetailsOrdine from "./components/DetailsOrdine";
import {env} from "../../configs/EnvironmentConfig";
import {isMobile} from "react-device-detect";
import axios from "axios";

function ConfigureSpedizione({checkout}) {

    const [contactData, setContactData] = useState({
        nome: '',
        cognome: '',
        email: '',
        telefono: ''
    });

    const [shippingData, setShippingData] = useState({
        citta: '',
        prov: '',
        via: '',
        cap: '',
        ncivico: ''
    });

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!contactData.nome) {
            newErrors.nome = "Il nome è obbligatorio";
        }
        if (!contactData.cognome) {
            newErrors.cognome = "Il cognome è obbligatorio";
        }
        if (!contactData.email) {
            newErrors.email = "La mail è obbligatoria";
        }
        if (!contactData.telefono) {
            newErrors.telefono = "Il numero di telefono è obbligatorio";
        }
        if (checkout.piano === 'medium' || checkout.piano === 'premium') {
            if (!shippingData.citta) {
                newErrors.citta = "La città è obbligatoria";
            }
            if (!shippingData.prov) {
                newErrors.prov = "La provincia è obbligatoria";
            }
            if (!shippingData.via) {
                newErrors.via = "L'indirizzo di spedizione è obbligatorio";
            }
            if (!shippingData.cap) {
                newErrors.cap = "Il CAP è obbligatorio";
            }
            if (!shippingData.ncivico) {
                newErrors.ncivico = "Il Numero Civico è obbligatorio";
            }
        }

        console.log(newErrors)
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onFinish = async (e) => {

        console.log(checkout)
        e.preventDefault(); // Previene l'invio standard del form

        // Valida il form
        if (!validateForm()) {
            return; // Se il form non è valido, interrompe l'esecuzione della funzione
        }

        let indirizzoSpedizione = {}

        if (checkout.locandina !== null) {
            indirizzoSpedizione = {
                nome: contactData.nome,
                cognome: contactData.cognome,
                email: contactData.email,
                telefono: contactData.telefono,
                citta: shippingData.citta,
                prov: shippingData.prov,
                via: shippingData.via,
                ncivico: shippingData.ncivico,
                cap: shippingData.cap
            };
        } else {
            indirizzoSpedizione = {
                nome: contactData.nome,
                cognome: contactData.cognome,
                email: contactData.email,
                telefono: contactData.telefono
            };
        }
        let body = {
            prodotto: 'SNAPMEMORIES',
            name: checkout.name,
            dateEvent: checkout.start,
            dateEventFinish: checkout.end,
            type: checkout.piano === 'unico' ? 'premium' : checkout.piano,
            messaggioPersonalizzatoSagra: checkout?.messaggioPersonalizzatoSagra,
            profilePhoto: checkout.image,
            profileVideo: checkout.video,
            theme: checkout.theme,
            prezzoTotale: checkout?.locandina !== null ?
                ((
                    (env.PIANO[checkout?.piano]) +
                    (parseInt(checkout?.locandina?.numeroLocandineAggiuntive) / 10 * 1000)
                ) / 100).toFixed(2)
                :
                ((
                    (env.PIANO[checkout?.piano])) / 100).toFixed(2)
            ,
            qrCodeShape: checkout.shape,
            locandina: JSON.stringify(checkout.locandina),
            emailCliente: contactData.email,
            indirizzoSpedizione: JSON.stringify(indirizzoSpedizione),
            categoriaEvento: checkout.categoria
        }

        const formData = {
            mode: "payment",
            line_items_stripe: [{
                price: env.STRIPE_CONFIG[checkout.piano],
                quantity: 1,
            }],
            metadata: body
        };

        if (
            (checkout?.locandina !== null && parseInt(checkout?.locandina?.numeroLocandineAggiuntive) !== 0)
        ) {
            const quantity = parseInt(checkout.locandina.numeroLocandineAggiuntive) / 5;
            formData.line_items_stripe.push({
                price: env.STRIPE_CONFIG['locandina'],
                quantity: quantity,
            })
        }

        console.log(formData)
        // Inviare i dati come JSON
        const response = await axios.post(env.API_ENDPOINT_SERVER_MAFFLE + "/maffle/create-checkout-session", formData)
        console.log(response.data.url)
        window.location.href = response.data.url

    };

    return (
        <>
            <section className="banner_section_checkout">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">Step numero 4</span>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12"
                             style={{
                                 display: "flex",
                                 flexDirection: "column",
                                 justifyContent: "center"
                             }}
                        >
                            <div className="banner_text"
                                 style={{
                                     margin: "10px"
                                 }}
                            >
                                <h2>Inserisci i tuoi recapiti</h2>
                            </div>
                            <form>
                                <div className="form-group-acquisto">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: isMobile || checkout?.piano === 'basic' || checkout?.piano === 'unico' ? "column" : "row"
                                    }}>
                                        <input
                                            required
                                            onChange={(e) => setContactData({
                                                ...contactData, nome: e.target.value
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder="Nome"
                                            value={contactData.nome}
                                            style={{
                                                margin: "10px"
                                            }}
                                        />
                                        <input
                                            required
                                            onChange={(e) => setContactData({...contactData, cognome: e.target.value})}
                                            type="text"
                                            className="form-control"
                                            placeholder="Cognome"
                                            value={contactData.cognome}
                                            style={{
                                                margin: "10px"
                                            }}
                                        />
                                    </div>
                                    {errors.nome && <div className="error-message">{errors.nome}</div>}
                                    {errors.cognome && <div className="error-message">{errors.cognome}</div>}
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: isMobile || checkout?.piano === 'basic' || checkout?.piano === 'unico' ? "column" : "row"
                                    }}>
                                        <input
                                            required
                                            onChange={(e) => setContactData({...contactData, email: e.target.value})}
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            value={contactData.email}
                                            style={{
                                                margin: "10px"
                                            }}
                                        />
                                        <input
                                            required
                                            onChange={(e) => setContactData({...contactData, telefono: e.target.value})}
                                            type="tel"
                                            className="form-control"
                                            placeholder="Numero di telefono"
                                            value={contactData.telefono}
                                            style={{
                                                margin: "10px"
                                            }}
                                        />
                                    </div>
                                    {errors.email && <div className="error-message">{errors.email}</div>}
                                    {errors.telefono && <div className="error-message">{errors.telefono}</div>}
                                </div>
                            </form>
                            {checkout?.locandina !== null &&
                                <>
                                    <div className="banner_text"
                                         style={{
                                             margin: "10px"
                                         }}>
                                        <h2>Fornisci i dati di spedizione per le tue locandine</h2>
                                    </div>
                                    <form>
                                        <div className="form-group-acquisto">
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: isMobile ? "column" : "row"
                                            }}>
                                                <input
                                                    required
                                                    id={"city"}
                                                    onChange={(e) => setShippingData({
                                                        ...shippingData,
                                                        citta: e.target.value
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Città"
                                                    style={{
                                                        margin: "10px"
                                                    }}
                                                    value={shippingData.citta}
                                                />
                                                <input
                                                    required
                                                    onChange={(e) => setShippingData({
                                                        ...shippingData,
                                                        prov: e.target.value
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Provincia"
                                                    value={shippingData.prov}
                                                    style={{
                                                        margin: "10px"
                                                    }}
                                                />
                                            </div>
                                            {errors.city && <div className="error-message">{errors.citta}</div>}
                                            {errors.province && <div className="error-message">{errors.prov}</div>}
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: isMobile ? "column" : "none"
                                            }}>
                                                <input
                                                    required
                                                    onChange={(e) => setShippingData({
                                                        ...shippingData,
                                                        via: e.target.value
                                                    })}
                                                    type="text"
                                                    id={"address"}
                                                    className="form-control"
                                                    placeholder="Indirizzo di spedizione"
                                                    value={shippingData.via}
                                                    style={{
                                                        margin: "10px"
                                                    }}
                                                />
                                            </div>
                                            {errors.via && <div className="error-message">{errors.via}</div>}
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: isMobile ? "column" : "row"
                                            }}>
                                                <input
                                                    required
                                                    onChange={(e) => setShippingData({
                                                        ...shippingData,
                                                        ncivico: e.target.value
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Numero Civico"
                                                    value={shippingData.ncivico}
                                                    style={{
                                                        margin: "10px"
                                                    }}
                                                />
                                                <input
                                                    required
                                                    onChange={(e) => setShippingData({
                                                        ...shippingData,
                                                        cap: e.target.value
                                                    })}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="CAP"
                                                    style={{
                                                        margin: "10px"
                                                    }}
                                                    value={shippingData.cap}
                                                />
                                            </div>
                                            {errors.ncivico && <div className="error-message">{errors.ncivico}</div>}
                                            {errors.cap && <div className="error-message">{errors.cap}</div>}

                                        </div>
                                    </form>

                                </>

                            }
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <DetailsOrdine checkout={checkout}/>
                        </div>
                    </div>

                    <div className="row" style={{
                        paddingTop: "30px"
                    }}>
                        <div className="col-lg-4 col-3">
                        </div>
                        <div className="col-lg-4 col-6">

                            <div className="btn_block">
                                <div>
                                    <button onClick={(e) => onFinish(e)}
                                            className="btn puprple_btn ml-0">Procedi al pagamento
                                    </button>
                                    <div className="btn_bottom"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-3">
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ConfigureSpedizione;