import * as React from "react"
const MastercardSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={23}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            fillOpacity={0.8}
            d="M0 3.795a3 3 0 0 1 3-3h30a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-16Z"
        />
        <path fill="#FF5F00" d="M14.214 4.991h7.571v13.607h-7.571z" />
        <path
            fill="#EB001B"
            d="M14.694 11.796A8.638 8.638 0 0 1 18 4.99a8.653 8.653 0 1 0 0 13.607 8.638 8.638 0 0 1-3.304-6.802Z"
        />
        <path
            fill="#F79E1B"
            d="M32 11.796a8.653 8.653 0 0 1-14 6.802A8.653 8.653 0 0 0 18 4.99a8.653 8.653 0 0 1 14 6.802v.003Z"
        />
        <path
            fill="#F79E1B"
            fillRule="evenodd"
            d="M31.865 16.82v.406l-.06.003v-.308l-.111.264h-.077l-.112-.264v.305h-.074v-.407h.091l.12.29.12-.29h.103Zm-.66.07v.336h-.06v-.337H31v-.07h.34v.07h-.134Z"
            clipRule="evenodd"
        />
    </svg>
)
export default MastercardSvg
