import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
} from "amazon-cognito-identity-js"
import {env} from "./EnvironmentConfig";


//Questo è quello vecchio autenticazione-produzione
const userPool = new CognitoUserPool({
    UserPoolId: env.LOGIN.cognitoConfig.UserPoolId,
    ClientId:  env.LOGIN.cognitoConfig.ClientId
})


export function getGoogleCurrentUser() {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem("accessToken");
        if (!token) {
            reject(new Error("No user found"));
            return;
        }
        const fetchData = async () => {

            try {
                const response = await fetch(`${env.LOGIN.cognitoConfig.CognitoDomain}/oauth2/userInfo`, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer' + " " + token,
                    },
                });
                if (!response.ok) {
                    reject(new Error(`HTTP error! status: ${response.status}`));
                }
                const data = await response.json();
                resolve(data);
            } catch (error) {
                reject(error)
            }
        };

        fetchData();
    });
}
export function getCognitoCurrentUser() {
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser()

        if (!cognitoUser) {
            reject(new Error("No user found"))
            return;
        }

        cognitoUser.getSession((err, session) => {
            if (err) {
                reject(err)
                return
            }

            cognitoUser.getUserAttributes((err, attributes) => {
                if (err) {
                    reject(err)
                    return
                }
                const userData = attributes.reduce((acc, attribute) => {
                    acc[attribute.Name] = attribute.Value
                    return acc
                }, {})


                resolve({ ...userData, username: cognitoUser.username })
            })
        })
    })
}

export function signIn(username, password) {
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        })
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        })
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                resolve(result)
            },
            onFailure: (err) => {
                reject(err)
            },
        })
    })
}
export function signOut() {
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser) {
        try {
            cognitoUser.signOut()
        } catch (err) {
            console.error("Error Sign Out Cognito")
        }

    }
}

export function signUp(username, email, password) {
    return new Promise((resolve, reject) => {
        userPool.signUp(
            email,
            password,
            [{ Name: "email", Value: email },{ Name: "name", Value: username }],
            null,
            (err, result) => {
                if (err) {
                    reject(err)
                    return
                }
                resolve(result.user)
            }
        )
    })
}
export function confirmSignUp(username, code) {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        })

        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                reject(err)
                return
            }
            resolve(result)
        })
    })
}
export function resendConfermationCode(username) {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        })

        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                reject(err)
                return
            }
            resolve(result)
        }, {})

    })
}


export function forgotPassword(username) {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        })

        cognitoUser.forgotPassword({
            onSuccess: () => {
                resolve()
            },
            onFailure: (err) => {
                reject(err)
            },
        })
    })
}

export function confirmPassword(username, code, newPassword) {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        })

        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: () => {
                resolve()
            },
            onFailure: (err) => {
                reject(err)
            },
        })
    })
}




export function googleSignOut(token) {
    return new Promise((resolve, reject) => {
        const clientId = env.LOGIN.ClientId;
        const clientSecret = env.LOGIN.cognitoConfig.GoogleAppClientSecret;

        // Codifica Base64 di client_id:client_secret
        const authHeader = 'Basic ' + btoa(`${clientId}:${clientSecret}`);

        const url = env.LOGIN.cognitoConfig.CognitoDomain+'/oauth2/revoke';

        const requestBody = new URLSearchParams({
            token: token,
            client_id: clientId,
        }).toString();

        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': authHeader,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: requestBody
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
}



